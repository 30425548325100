/*
 * @Author: liukun
 * @Date: 2021-06-10 15:46:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-06-23 09:06:27
 * @Description: 
 */
export default [
    {
        path: '/publicPage/problemFeedback',
        name: 'problemFeedback',
        component: () => import(/* webpackChunkName: "common" */ './Index.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/problemFeedbackCreate',
        name: 'problemFeedbackCreate',
        component: () => import(/* webpackChunkName: "common" */ './Create.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/problemDetail',
        name: 'problemDetail',
        component: () => import(/* webpackChunkName: "common" */ './detail.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    }
];