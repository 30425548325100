export default [
    {
        path: '/publicPage/claimApplication',
        name: 'claimApplication',
        component: () => import(/* webpackChunkName: "common" */ './claimApplication.vue'),
        meta: {
            authPass: true,
            title: '理赔申请列表'
        }
    },
    {
        path: '/publicPage/addClaimApplication',
        name: 'addClaimApplication',
        component: () => import(/* webpackChunkName: "common" */ './addClaimApplication.vue'),
        meta: {
            authPass: true,
            title: '理赔申请'
        }
    },
    {
        path: '/publicPage/claimApplicationDetail',
        name: 'claimApplicationDetail',
        component: () => import(/* webpackChunkName: "common" */ './claimApplicationDetail.vue'),
        meta: {
            authPass: true,
            title: '理赔申请详情'
        }
    }
];
