export default [
    {
        path: '/publicPage/applyAdd',
        name: 'applyAdd',
        component: () => import('./drawDeposit.vue'),
        meta: {
            authPass: true,
            title: '申请提款'
        }
    },
    {
      path: '/publicPage/loanManage',
      name: 'loanManage',
      component: () => import('./lendMoney.vue'),
      meta: {
          authPass: true,
          title: '放款管理'
      }
    },
    {
      path: '/publicPage/serviceFee',
      name: 'serviceFee',
      component: () => import('./technicalServiceFee.vue'),
      meta: {
          authPass: true,
          title: '信息技术服务费'
      }
    }
];
