import service from '../service'
import store from '../../store'

export default {
  // 列表
  queryPage(params) {
    return service.post('/whale-pc/role/queryRolePage', params)
  },
  detail(params) {
    return service.post('/whale-pc/role/detail', params)
  },
  listMenuTreeVO(params) {
    return service.post('/whale-pc/menu/listMenuTreeVO', params)
  },
  addOrUpdateRoleOperation(params) {
    return service.post('/whale-pc/role/addOrUpdateRoleOperation', params)
  },
  delRole(params) {
    return service.post('/whale-pc/role//del', params)
  },
  listMenuVO(params) {
    return service.post('/whale-pc/menu/listMenuVO', params)
  },
  selectUserPermissionList(params) {
    return service.post('/whale-pc/permission/selectUserPermissionList', params, {
      load: false
    })
  },
  leftMenuCornerMarker(params) {
    return service.post('/whale-merge-pc/home/leftMenuCornerMarker', params, {
      load: false
    })
  },
  selfInfo(params) {
    return service.post('/whale-pc/member/selfInfo', params, {
      load: false
    })
  },
  queryDictConfig(params) {
    return service.post('/whale-pc/dictConfig/queryDictConfig', params, {
      load: false
    })
  },
  bubbleGuide(params) {
    return service.post('/whale-pc/bubbleGuide/query', params, {
      load: false
    })
  },
  bubbleGuideAdd(params) {
    return service.post('/whale-pc/bubbleGuide/add', params)
  },
  // 开启账户额度控制
  openAssignAccount(params) {
    return service.post('/ams/quota/assign/openAssignAccount', params)
  },
  // 关闭账户额度控制
  closeAssignAccount(params) {
    return service.post('/ams/quota/assign/closeAssignAccount', params)
  },
  // 查询操作记录
  queryRecordList(params) {
    return service.post('/ams/quota/assign/queryRecordList', params)
  },
  // 调整账户额度控制
  modifyAssignAccount(params) {
    return service.post('/ams/quota/assign/modifyAssignAccount', params)
  },
  // 查询额度分配账户列表
  queryCanAssignAccountList(params) {
    return service.post('/ams/quota/assign/queryCanAssignAccountList', params)
  },
  querySubsidiaryList(params) {
    return service.post('/ams/quota/assign/querySubsidiaryList', params)
  },
  // 校验该用户是否设置智运宝资金密码
  isSettingPassword(params) {
    return service.post('/ams/quota/assign/isSettingPassword', params)
  },
  // 校验该用户智运宝资金密码
  checkPassword(params) {
    return service.post('/ams/quota/assign/checkPassword', params)
  },
  // 我的运单价格展示权限
  queryMyWaybillPricePermission(params) {
    return service.post('/whale-pc/role/queryMyWaybillPricePermission', params)
  },
  saveOrUpdatePricePermission(params) {
    return service.post('/whale-pc/dataField/saveOrUpdatePricePermission', params)
  },
  // 中资相关接口
 
  // 1.监管平台查询功能菜单树 /menu/queryUcMenuTreezzTree
    // 传参：systemFlag
    // 返回：
    //     menuId 菜单ID
    //     parentId 父级ID
    //     funcCode 菜单编码
    //     name 菜单名称
    //     url 菜单url
    //     type 类型   0：目录   1：菜单 2:按钮
    //     children 子集


    // 2.监管平台新增或修改角色 /menu/addOrUpdateRoleOperationzz
    // 传参和返回与 role/addOrUpdateRoleOperation接口一致
    //   3.监管平台查询角色列表 /menu/queryRolePagezz
    // 传参和返回与 /role/queryRolePage一致

    // 4.监管平台删除角色 /menu/delzz
    // 传参和返回与 /role/del一致
    // 5.监管平台查询角色详情 /menu/detailzz
    // 传参和返回与 /role/detail一致
     // 监管平台查询功能菜单树
     queryUcMenuTreezzTree(params) {
      return service.post('/whale-pc/menu/queryUcMenuTreezzTree', params)
    },
    // 监管平台新增或修改角色
    addOrUpdateRoleOperationzz(params) {
      return service.post('/whale-pc/menu/addOrUpdateRoleOperationzz', params)
    },
    // 监管平台查询角色列表
    queryRolePagezz(params) {
      return service.post('/whale-pc/menu/queryRolePagezz', params)
    },
    // 监管平台删除角色
    delzz(params) {
      return service.post('/whale-pc/menu/delzz', params)
    },
    // 监管平台查询功能菜单树
    detailzz(params) {
      return service.post('/whale-pc/menu/detailzz', params)
    },
    // 用户拥有的角色
    findUserRoleByUserIdzz(params) {
      return service.post('/whale-pc/menu/findUserRoleByUserIdzz', params)
    },


 
}