import roleApi from '@/services/info/role'
const state = {
	token: '',
	userinfo: '',
	account: {},
	dictConfig: {},
	warningFlag: false
}

const getters = {
	tipsCount(state){
		return state.account.tipsCount || 0;
	},
	dictConfig(state){
		let dictConfig = localStorage.getItem('dictConfig');
		let dictConfigObj = {};
		if(dictConfig){
			dictConfigObj = JSON.parse(dictConfig);
		}
		return dictConfigObj;
	},
	warningFlag(state){
		return state.warningFlag
	}
}

const mutations = {
	SET_WARNING_FLAG: (state, flag) => {
		state.warningFlag = flag
	},
	SET_TOKEN: (state, token) => {
		state.token = token
	},
	SET_USERINFO: (state, userinfo) => {
		state.userinfo = userinfo
	},
	SET_ACCOUNT: (state, account) => {
		// 
		state.account = Object.assign({}, state.account, account)
		// roleApi.selfInfo().then(res => {
		//     state.account = res.data;
		//     var account = res.data;
		if (account && account.userName) {
			localStorage.setItem('userName', account.userName)
		}
		if (account && account.memberName) {
			localStorage.setItem('memberName', account.memberName)
		}
		if (account && account.userId) {
			localStorage.setItem('uuUserId', account.userId)
			localStorage.setItem('userId', account.userId)
		}
		if (account && account.mobile) {
			localStorage.setItem('mobile', account.mobile)
		}
		if (account && account.headUrl) {
			localStorage.setItem('headUrl', 'https://img.zczy56.com/' + account.headUrl)
		}
		localStorage.setItem('tipsCount', account.tipsCount || 0)
		// })
	},
	SET_ROLE_DICT: (state, dictConfig) => {
		let dictConfigObj = {};
        let dictCodes = dictConfig.map(it => it.dictCode);
        dictCodes = Array.from(new Set(dictCodes));
        dictCodes.forEach(it => {
            let data = dictConfig.filter(config => config.dictCode === it);
            if(data[0].dictKey){
                dictConfigObj[it] = data.map(item => {
                    return {
                        [item.dictKey]: item.value,
                    }
                });
            }else {
                dictConfigObj[it] = data.map(it => it.value).join(',')
            }
        })
		state.dictConfig = dictConfigObj;
		localStorage.setItem('dictConfig', JSON.stringify(dictConfigObj));
	},
    // 用于退出登录重置数据
    resetState(state) {
        state = {
            token: '',
            userinfo: '',
            account: {},
            dictConfig: {}
        };
    }
}

const actions = {
	logout({ commit }) {
		return new Promise((resolve, reject) => {
			commit('SET_TOKEN', '')
			resolve()
		})
	}
}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}
