export default [
    {
        path: '/publicPage/receiptSignIn',
        name: 'receiptSignIn',
        component: () => import(/* webpackChunkName: "common" */ './receiptSignIn.vue'),
        meta: {
            authPass: true,
            title: '回单签收'
        }
    },
    {
        path: '/publicPage/detailSiginIn',
        name: 'detailSiginIn',
        component: () => import(/* webpackChunkName: "common" */ './detailSiginIn.vue'),
        meta: {
            authPass: true,
            title: '回单签收详情'
        }
    }
];
