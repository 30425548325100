export default [
	{
		path: '/publicPage/discounts',
		name: 'discounts',
		component: () => import(/* webpackChunkName: "common" */ './index.vue'),
		meta: {
			authPass: true,
			title: '货主优惠奖励报表'
		}
	}
]
