export default [
    {
        path: '/publicPage/containerManage',
        name: 'containerManage',
        component: () => import(/* webpackChunkName: "common" */ './containerManage.vue'),
        meta: {
            authPass: true,
            title: '集装箱管理'
        }
    }
];
