export default [
	{
		path: '/publicPage/transportContractManager',
		name: 'transportContractManager',
		component: () => import(/* webpackChunkName: "common" */ './index.vue'),
		meta: {
			authPass: true,
			title: '运输合同管理'
		}
	},
	{
		path: '/publicPage/transportContractManager/create',
		name: 'transportContractManagerCreate',
		component: () => import(/* webpackChunkName: "common" */ './createOrModify.vue'),
		meta: {
			authPass: true,
			title: '运输合同管理'
		}
	},
]
