import service from '../service';
import store from '../../store';

const api = {
  // 回单确认列表
  receiptConfirmList(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/receiptConfirmList', params);
  },
  // 回单确认 发单员下拉
  queryReceiptConfirm(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryConsignorChildList', params);
  },
  // 货主回单下拉信息
  queryConsignorOrderFilePc(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryConsignorOrderFilePc', params);
  },
  // 编辑标签
  editSelfComment(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/editSelfComment', params);
  },
  // 单个回单获取订单数据
  queryConsignorSingleReceiptOrderData(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryConsignorSingleReceiptOrderData', params);
  },
  // 回单打回查询标签
  findBackOrderTag(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/findBackOrderTag', params);
  },
  // 回单打回
  consignorReceiptRepulse(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/consignorReceiptRepulse', params);
  },
  // 同意调整议价
  agreeDiscussPrice(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/consignorCarrierAgreeReceipt', params);
  },
  // 获取批量的回单数据
  queryConsignorBatchReceiptOrderData(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryConsignorBatchReceiptOrderData', params);
  },
  // 回单查询装卸费审核状态
  queryLoadStateByDetailIds(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryLoadStateByDetailIds', params);
  },
  // 回单提交前判断订单是否有异常
  queryBatchOrderExceptionNotToReceiptFlag(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryBatchOrderExceptionNotToReceiptFlag', params);
  },
  // 回单提交单个回单提示信息
  queryConsignorSingleReceiptMsg(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryConsignorSingleReceiptMsg', params);
  },
  // 批量回单提交
  confirmReceiptReconsiderBatch(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/confirmReceiptReconsiderBatch', params);
  },
  // 单个回单后自动提交结算申请
  confirmsettleApplySubmitAft(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/settleApplySubmitAftConfirm', params);
  },
  // 回单待处理
  consignorBatchToReceiptDealConfirm(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/consignorBatchToReceiptDealConfirm', params);
  },
  // 单个回单判断有无异常
  queryOrderExceptionNotToReceiptFlag(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryOrderExceptionNotToReceiptFlag', params);
  },
  // 单个回单提交数据 http://wiki.zczy56.com/pages/viewpage.action?pageId=39978497
  consignorSingleConfirm(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/consignorSingleConfirm', params);
  },
  // 回单导出
  exportReceiptConfirm(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/exportReceiptConfirm', params);
  },
  // 查询异步导出的结果列表
  queryReceiptExportFilesList(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryReceiptExportFilesList', params)
  },
  // 单个回单切换超亏吨
  queryDeductionConfigurationByRuleId(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryDeductionConfigurationByRuleId', params);
  },
  // 单个回单切换超亏吨
  insertConfirmImg(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/insertConfirmImg', params);
  },

  // 结算申请列表
  settleAccountListData(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleAccountListData', params);
  },
  // 结算申请列表查询展示列
  querySearchRedisByUserId(params) {
    return service.post('/oms-consignor-pc/settle/apply/querySearchRedisByUserId', params);
  },
  // 结算申请列表修改展示列
  saveSearchToRedis(params) {
    return service.post('/oms-consignor-pc/settle/apply/saveSearchToRedis', params);
  },
  // 结算平台主体
  queryConsignorSub() {
    return service.post('/oms-consignor-pc/settle/apply/consignor/queryConsignorSub');
  },
  // 结算申请查询结算金额
  querySettleAccountNeedMoney(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/querySettleAccountNeedMoney', params);
  },
  // 结算申请判断余额是否充足
  findSettleAccountMoneyIsEnough(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/findSettleAccountMoneyIsEnough', params);
  },
  // 结算申请提交
  settleApplyCommit(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleApplyCommit', params);
  },
  // 结算申请打回
  settleApplyReturn(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleApplyReturn', params);
  },
  // 结算申请导出
  exportSettleAccountListData(params) {
    return service.post('/oms-consignor-pc/settle/apply/exportSettleAccountListData', params);
  },
  // 结算申请导出结果查询
  queryConsignorExportSettleFilesList(params) {
    return service.post('/oms-consignor-pc/settle/apply/queryConsignorExportSettleFilesList', params);
  },
  // 查询主体及主体待结算运单数量
  queryOrderSettleCountGroup(params) {
    return service.post('/oms-consignor-pc/settle/apply/queryOrderSettleCountGroup', params);
  },

  // 结算管理列表
  settleManageListData(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleManageListData', params);
  },
  // 结算管理导出
  exportSettleManageListData(params) {
    return service.post('/oms-consignor-pc/settle/apply/exportSettleManageListData', params);
  },
  // 结算管理详情
  settleManageDetail(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleManageDetail', params);
  },

  // 结算导入列表
  consignroSettleApplyList(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignroSettleApplyList', params);
  },
  // 结算导入导出
  exportConsignorSettleApplyData(params) {
    return service.post('/oms-consignor-pc/settle/apply/exportConsignorSettleApplyData', params);
  },
  // 结算导入模板下载
  getModlueDownLoad(params) {
    return service.post('/oms-consignor-pc/statics/upload/settleApply.xlsx', params);
  },
  // 结算导入
  consignroSettleApply(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignroSettleApply', params);
  },

  // 回单签收列表
  queryExpressOrderList(params) {
    return service.post('/oms-consignor-pc/order/consignor/queryExpressOrderList', params);
  },
  // 回单签收确认签收
  doConsignorSign(params) {
    return service.post('/oms-consignor-pc/order/consignor/doConsignorSign', params);
  },
  // 回单签收问题反馈
  addProblemFeedback(params) {
    return service.post('/whale-merge-pc/problemFeedback/addProblemFeedback', params);
  },
  // 回单签收打回
  doPass(params) {
    return service.post('/oms-consignor-pc/order/consignor/doPass', params);
  },
  // 回单签收代签收
  consignorBeforeSignExpress(params) {
    return service.post('/oms-consignor-pc/order/consignor/consignorBeforeSignExpress', params);
  },
  // 回单签收详情
  queryExpressOrderDetail(params) {
    return service.post('/oms-consignor-pc/order/consignor/queryExpressOrderDetail', params);
  },
  // 回单签收 下载
  exportConsignorExpress(params) {
    return service.post('/oms-consignor-pc/order/consignor/exportConsignorExpress', params);
  },
  // 回单签收，撤销快递打回
  batchClanceExpressRefusc(params) {
    return service.post('/oms-consignor-pc/order/consignor/batchClanceExpressRefusc', params)
  },
  // 图片旋转保存
  saveRotateImg(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/updateTransportFileByPhotoId', params);
  },
  // 历史年份查询接口
  queryHistoryYearList() {
    return service.post('/oms-consignor-pc/order/advance/queryHistoryYearList');
  },
  // 校验货主统计报表是否展示提示
  consignorOrderReportTipsFlag(params) {
    return service.post('/oms-consignor-pc/consignor/statisticalForm/consignorOrderReportTipsFlag', params);
  },
  // 更改校验货主统计报表是否展示提示
  changeConsignorOrderReportTipsFlag(params) {
    return service.post('/oms-consignor-pc/consignor/statisticalForm/changeConsignorOrderReportTipsFlag', params);
  },
  // 前后端分离查询货主备案卸货是否曾经配置
  queryHasConfigedBeiAn(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryHasConfigedBeiAn', params);
  },
  // 超载管控-货主/承运方增加登陆超载运单提示
  queryOverWeightTips(params) {
    return service.post('/oms-consignor-pc/order/common/queryOverWeightTips', params);
  },
  // 回单确认时修改收发货吨位后查询如果有亏涨吨，查询亏涨吨规则
  queryLossRiseRule(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryLossRiseRule', params);
  },
  // 回单确认时修改规则返回金额 入参是规则明细和金额
  queryLossRiseMoneyByChangeRuleDetailData(params) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryLossRiseMoneyByChangeRuleDetailData', params);
  },
  // 回单确认时切换规则返回金额 入参是规则明细和金额
  queryReceiptLossRiseDetailInfoByPcChangeRuleId(params) {
    return service.post(
      '/oms-consignor-pc/consignor/receiptConfirm/queryReceiptLossRiseDetailInfoByPcChangeRuleId',
      params
    );
  },
  // 结算申请待审核打回接口
  settleApplyAuditReturn(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/settleApplyAuditReturn', params);
  },
  // 结算申请提交到待审核接口
  summitToAudit(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/summitToAudit', params);
  },
  // 结算申请提交到待审核接口
  getUserInfo(params) {
    return service.post('/oms-consignor-pc/consignor/bid/manage/getUserInfo', params);
  },
  //
  querySelectSettleData(params) {
    return service.post('/oms-consignor-pc/settle/apply/consignor/querySelectSettleData', params)
  },

  // 回单确认列表展示列：修改
  saveReceiptSearchToRedis(params = {}) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/saveReceiptSearchToRedis', params)
  },
  // 回单确认列表展示列：查询
  queryReceiptSearchRedisByUserId(params = {}) {
    return service.post('/oms-consignor-pc/consignor/receiptConfirm/queryReceiptSearchRedisByUserId', params)
  },
  //西藏：运营数据列表
  queryOperationData(params = {}) {
    return service.post('/oms-consignor-pc/xizangController/queryOperationData', params)
  },
  //西藏：运营数据导出
  exportOperationData(params = {}) {
    return service.post('/oms-consignor-pc/xizangController/exportOperationData', params)
  },
  //西藏：查询开货货主
  queryRelatedDevelopConsignor(params = {}) {
    return service.post('/oms-consignor-pc/xizangController/queryRelatedDevelopConsignor', params)
  },
  //西藏：运营数据明细列表
  queryConsignorOrderReportResultXZ(params = {}) {
    return service.post('/oms-consignor-pc/consignor/statisticalForm/queryConsignorOrderReportResultXZ', params)
  },
  //西藏：运营数据明细导出
  consignorOrderReportExportXZ(params = {}) {
    return service.post('/oms-consignor-pc/consignor/statisticalForm/consignorOrderReportExportXZ', params)
  },
  //西藏：查询子账号
  queryConsignorChildListXZ(params = {}) {
    return service.post('/oms-consignor-pc/xizangController/queryConsignorChildList', params)
  },



};
export default api;