import layout from '@/pages/layout/layout';
import myFortune from './myFortune';
import orderManagement from './orderManagement';
import invoiceManage from './invoice';
import customerService from './customerService';
import setting from './setting';
import count from './count';
import personCenter from './personCenter';
import QRCode from './QRCode';
import deposit from './deposit';
import propertyCenter from './propertyCenter';
import dashboard from './dashboard.vue';
// todo ownerFreightloan
import ownerFreightloan from './ownerFreightloan';
import transportContractManager from './transportContractManager';
import wareHouseManage from './wareHouseManage';
import primaryAccountNumber from "./primaryAccountNumber";
import transportPlan from './orderManagement/transportPlan';
import consignorTrackList from './consignorTrackList';
import smartOperation from './smartOperation'
import fenceWarning from './fenceWarning'
import routeFenceRelationship from './fenceWarning/routeFenceRelationship'
export default [{
    path: '/publicPage',
    name: 'publicPage',
    component: layout,
    children: [...myFortune, ...orderManagement, ...invoiceManage, ...customerService, ...setting, ...count, ...personCenter, 
        QRCode, ...deposit, ...propertyCenter, ...dashboard, ...transportContractManager, ...wareHouseManage, primaryAccountNumber,
         ...ownerFreightloan, ...transportPlan, ...consignorTrackList, ...smartOperation, ...fenceWarning,...routeFenceRelationship]
},{
    path: '/contractPage',
    name: 'contractPage',
    component: () => import(/* webpackChunkName: "common" */ './orderManagement/contract/contractPage.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    path: '/order/importOrder/jumpToExcelImportOrder',
    name: 'jumpToExcelImportOrder',
    component: () => import(/* webpackChunkName: "common" */ './orderManagement/importOrder/jumpToExcelImportOrder.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    // 消息列表
    path: '/messageList',
    name: 'messageList',
    component: () => import(/* webpackChunkName: "common" */ './messageList/messageList.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    // 消息详情
    path: '/messageDetail',
    name: 'messageDetail',
    component: () => import(/* webpackChunkName: "common" */ './messageList/messageDetail.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    // 协议说明
    path: '/serviceAgreement',
    name: 'serviceAgreement',
    component: () => import(/* webpackChunkName: "common" */ '@/views/components/wayBill/serviceAgreement.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    // 消息列表
    path: '/announcementList',
    name: 'announcementList',
    component: () => import(/* webpackChunkName: "common" */ './announcement/announcementList.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},{
    // 消息详情
    path: '/announcementDetail',
    name: 'announcementDetail',
    component: () => import(/* webpackChunkName: "common" */ './announcement/announcementDetail.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
}
,{
    // empty页面
    path: '/announcementDetail',
    name: 'announcementDetail',
    component: () => import(/* webpackChunkName: "common" */ './announcement/announcementDetail.vue'),
    meta: {
        authPass: true,
        title: '',
        noTitle: true
    }
},
{
    path: '/Jdlogin',
    name: 'Jdlogin',
    component: () => import('./Jdlogin.vue'),
    meta: {
        authPass: true,
        title: '京东贷登录'
    }
}

];
