export default [
    {
        path: '/publicPage/waybillPublish',
        name: 'waybillPublish',
        component: () => import(/* webpackChunkName: "common" */ './waybillPublish.vue'),
        meta: {
            authPass: true,
            title: '发布运单',
        }
    }
];
