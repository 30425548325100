export default [
    {
        path: '/publicPage/receiptConfirmationNew',
        name: 'receiptConfirmationNew',
        component: () => import(/* webpackChunkName: "common" */ './index.vue'),
        meta: {
            authPass: true,
            title: '回单确认'
        }
    }
];