import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import modules from './modules';
import vipKefuJs from '@/assets/js/vipKefu';
Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);
console.log('---modulesFiles----');
console.log(modulesFiles.keys());

// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)

//   console.log('--------in reduce--------')
//   console.log(moduleName)
//   console.log(value)

//   modules[moduleName] = value.default
//   return modules
// }, {})

import roleApi from '@/services/info/role';

const store = new Vuex.Store({
  /**
   *  解决页面刷新菜单未刷新
   */
  // plugins: [
  //     createPersistedState({
  //         reducer(state) {
  //             return {
  //                 log: {
  //                     ...state.log
  //                 }
  //             };
  //         }
  //     })
  // ],
  modules,
  actions: {
    resetState(context, data) {
      let types = Object.keys(context.rootState);
      let localStorageData = JSON.parse(JSON.stringify(localStorage));
      // 除掉登陆信息，其他存在localStorage里面的信息都清掉
      for (var k in localStorageData) {
        if (k !== 'loginInfo') {
          localStorage.removeItem(k);
        }
      }

      types.forEach(item => {
        if (modules[item].mutations.resetState) {
          context.commit(`${item}/resetState`);
        }
      });
    },
    setCurrentMenu(context, data) {
      let types = Object.keys(context.rootState);

      types.forEach(item => {
        if (modules[item].mutations.setCurrentMenu) {
          context.commit(`${item}/setCurrentMenu`, data);
        }
      });
    },
    SET_ACCOUNT(context, data) {
      return roleApi
        .selfInfo()
        .then(res => {
          if (res.data.vipCustomer == 1) {
            vipKefuJs.addVipKefuData(res.data);
          }
          context.commit(`user/SET_ACCOUNT`, res.data);
        })
        .catch(err => {});
      // let types = Object.keys(context.rootState);

      // types.forEach(item => {
      //     if (modules[item].mutations.SET_ACCOUNT) {
      //         context.commit(`${item}/SET_ACCOUNT`, data);
      //     }
      // });
    },
  },
  // ,
  // plugins: [
  //   createPersistedState()
  // ]
});

export default store;
