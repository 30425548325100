/*
 * @Author: liukun
 * @Date: 2021-06-10 15:46:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-06-21 14:00:08
 * @Description: 
 */
export default [
    {
        path: '/publicPage/questionnaire',
        name: 'questionnaire',
        component: () => import(/* webpackChunkName: "common" */ './questionnaire.vue'),
        meta: {
            authPass: true,
            title: '问卷调查'
        }
    },
    {
        path: '/publicPage/questionnaireDetails',
        name: 'questionnaireDetails',
        component: () => import(/* webpackChunkName: "common" */ './questionnaireDetails.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    }
];
