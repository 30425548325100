export default [
    // 获取模和公钥指数
    '/whale-aio-sso-server/app/getPwdKey',

    /** ********** 登录页 *************/
    '/whale-aio-sso-server/app/login',

    // 系统退出
    '/whale-aio-sso-server/app/logout',

    // 登录次数过多 是否需要图片验证
    '/getUserPassWordWrongTimes',

    '/captcha.jpg',

    '/checkImageVerifyCode',
    // 手机登录 发送登录验证码
    '/whale-pc/verify-code/sendVerifyLoginCode',
    // 验证是否需要图片验证码
    '/whale-pc/verify-code/isShowImageVerifyCode',
    // 发送短信或语音验证码
    '/whale-pc/verify-code/sendVerifyCode',
    // 验证邀请码
    '/whale-pc/register/checkInviteCode',
    // 注册
    '/whale-pc/register/registerMember',
    // 找回密码第一步
    '/whale-pc/password/findPwdStep1',
    // 服务端审核验证码
    '/whale-pc/verify-code/checkVerifyCode',
    // 找回密码步骤三
    '/whale-pc/password/findPwdStep3',
    // 计算密码等级
    '/whale-pc/password/countPasswordGrade',
    // 查询车辆类型，车长字典
    '/whale-pc/vehicle/queryDictByCode',
    // 查询车源广场列表
    '/whale-pc/vehicle/sourceOfVehicleSource',
    // 新闻公告列表
    '/whale-pc/news/queryNewsList',
    // 新闻公共详情
    '/whale-pc/news/queryNewsDetail',
    // 首页第一屏幕数字
    '/homeShowNumbers.json',
    // 生成二维码接口
    '/whale-pc/qr-code/gen-login-code',
    // 二维码扫描长轮询
    '/whale-aio-sso-server/app/sys/loginWeb',
    // 联系人类型字典
    '/whale-pc/vehicle/queryDictListByCode', 
    '/whale-pc/member/queryPlateFormTreatyListNew'
]