export default [
    {
        path: '/publicPage/abnormalWayBill',
        name: 'abnormalWayBill',
        component: () => import(/* webpackChunkName: "common" */ './abnormalWayBill.vue'),
        meta: {
            authPass: true,
            title: '运单异常管理'
        }
    },
    {
        path: '/publicPage/exceptionOrderDetail',
        name: 'exceptionOrderDetail',
        component: () => import(/* webpackChunkName: "common" */ './exceptionOrderDetail.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
];
