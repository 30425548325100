<template>
  <div class="app-main">
    <MemberHeader></MemberHeader>
    <div class="main page-center">
      <div class="left-part">
        <sideBar @on-reload="reload"></sideBar>
      </div>
      <div class="right-panel">
        <div
          :class="{ hasHam: currentMenus }"
          class="router"
        >
          <el-breadcrumb
            class="right-panel-breadcrumb"
            separator-class="el-icon-arrow-right"
            v-if="$route.query.breadcrumb"
          >
            <el-breadcrumb-item
              :class="{ 'current-breadcrumb': item.name === $route.name }"
              :key="index"
              @click.native="clickBreadcrumb(item)"
              v-for="(item, index) in breadcrumb"
            >{{ item.title }}</el-breadcrumb-item>
          </el-breadcrumb>
          <!-- <keep-alive>
            <router-view
              :key="$route.name + $route.fullPath"
              class="router-page"
              v-if="$route.query.keepAlive === 'true' && visible"
            />
          </keep-alive> -->
          <keep-alive :include="cacheList">
            <router-view
			  v-if="visible"
              :class="{ noTitle: $route.meta.noTitle }"
              :key="$route.name + $route.fullPath"
              class="router-page"
            />
          </keep-alive>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import sideBar from './components/sideBar/sideBar'
import MemberHeader from '@/components/MemberHeader.vue'

import { mapGetters, mapMutations } from 'vuex'

export default {
	name: 'layoutPage',
	components: { sideBar, MemberHeader },
	data() {
		return {
			cacheList: ['invoiceApply', 'receiptSign'],
			title: Vue.$config.projectName,
			urlQuery: {},
			showPageTab: false,
			pageHeader: Vue.$config.pageHeader,
			visible: true
		}
	},
	created() {
		console.log(this.cacheList, 'dffffff')
    },
	computed: {
		...mapGetters('log', ['currentMenus', 'menuList']),
		breadcrumb() {
			if (this.$route.query.breadcrumb) {
				return JSON.parse(this.$route.query.breadcrumb)
			}
			return null
		}
	},
	watch: {
		'$route.query.keepAlive'() {
			const keepAlive = this.$route.query.keepAlive
			if (!keepAlive || keepAlive === 'false') {
				// this.$nextTick(res => {
				//     this.reload();
				// })
				this.reload()
			}
		},
	},
	methods: {
		...mapMutations('log', [
			'setActiveSubMenu',
			'setTopCurrentMenus',
			'setCurrentMenus'
		]),
		reload() {
			this.visible = false
			this.$route.query.keepAlive = false
			this.$nextTick(() => {
				document.getElementsByClassName('app-main')[0].scrollTop = 0
				this.visible = true
			})
		},
		clickBreadcrumb(item) {
			this.$router.push(item.name)
			let menu = this.menuList.find(it => it.name === item.name)
			this.setActiveSubMenu(menu.id)
		}
	}
}
</script>

<style lang="scss" scoped>
.app-main {
	background-color: #f0f1f6;
	height: 100vh;
	overflow-y: auto;
	overflow-x: auto;

	.page-center {
		width: 1200px;
		margin: 0px auto;
	}

	.main {
		position: relative;
		padding: 0px;
		overflow: hidden;
		display: flex;
		.left-part {
			height: 100%;
			// float: left;
			width: 208px;
			// background-color: #e1e6eb;
			padding-bottom: 9999px;
			margin-bottom: -9999px;
			display: inline;
			overflow: hidden;
		}
		.right-panel {
			flex: 1;
			// float: right;
			height: auto;
			width: 992px;
			.right-panel-breadcrumb {
				background: #fff;
				padding: 16px;
				margin-bottom: 12px;
				border-radius: 4px;
				.current-breadcrumb {
					.el-breadcrumb__inner {
						font-size: 16px;
						font-weight: 600;
						color: #333;
					}
				}
			}
			.router {
				position: relative;
				box-sizing: border-box;
				padding: 0px 16px 16px 16px;
				height: 100%;
				.router-page {
					background-color: #fff;
					border-radius: 4px;
					&.noTitle {
						background: transparent;
					}
				}
			}
		}
		.footer {
			line-height: 36px;
			text-align: center;
			height: 36px;
			color: #c0c0c1;
		}
	}
	.clear {
		clear: both;
	}
}
</style>
<style lang="scss">
.right-panel-breadcrumb {
	.current-breadcrumb {
		.el-breadcrumb__inner {
			font-size: 16px;
			font-weight: 600;
			color: #333;
			cursor: default;
		}
	}
	.el-breadcrumb__item:last-child .el-breadcrumb__inner,
	.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
	.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
	.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
		font-size: 16px;
		font-weight: 600;
		color: #333;
	}
	.el-breadcrumb__item .el-breadcrumb__inner:hover {
		font-size: 16px;
		font-weight: 600;
		color: #5086fc;
	}
	.el-breadcrumb__item {
		font-weight: 400;
		color: #606266;
		cursor: text;
		cursor: pointer;
	}
}
</style>
