export default [
    {
        path: '/publicPage/transportPlan',
        name: 'transportPlan',
        component: () => import(/* webpackChunkName: "common" */ './transportPlan.vue'),
        meta: {
            authPass: true,
            title: '运输计划',
        }
    },
    {
        path: '/publicPage/updateDataPage',
        name: 'updateDataPage',
        component: () => import(/* webpackChunkName: "common" */ './updateDataPage.vue'),
        meta: {
            authPass: true,
            title: '运输计划',
        }
    },
    {
        path: '/publicPage/transportPlanDetail',
        name: 'transportPlanDetail',
        component: () => import(/* webpackChunkName: "common" */ './transportPlanDetail.vue'),
        meta: {
            authPass: true,
            title: '运输计划详情',
            noTitle: true
        }
    }
];
