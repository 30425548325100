export default [
    {
        path: '/publicPage/changeManegement',
        name: 'changeManegement',
        component: () => import(/* webpackChunkName: "common" */ './queryChangeList.vue'),
        meta: {
            authPass: true,
            title: '变更管理'
        }
    },
    {
        path: '/publicPage/changeHistory',
        name: 'changeHistory',
        component: () => import(/* webpackChunkName: "common" */ './queryChangeHistory.vue'),
        meta: {
            authPass: true,
            title: '变更管理历史'
        }
    },
    {
        path: '/publicPage/changesComplete',
        name: 'changesComplete',
        component: () => import(/* webpackChunkName: "common" */ './changesComplete.vue'),
        meta: {
            authPass: true,
            title: '发起变更'
        }
    },
    {
        path: '/publicPage/changesBeforeDespat',
        name: 'changesBeforeDespat',
        component: () => import(/* webpackChunkName: "common" */ './changesBeforeDespat.vue'),
        meta: {
            authPass: true,
            title: '发起变更'
        }
    },
    {
        path: '/publicPage/changesRelease',
        name: 'changesRelease',
        component: () => import(/* webpackChunkName: "common" */ './changesRelease.vue'),
        meta: {
            authPass: true,
            title: '发起变更',
            noTitle: true
        }
    }
];
