export default [
    {
        path: '/publicPage/goodsSpecificat',
        name: 'goodsSpecificat',
        component: () => import(/* webpackChunkName: "common" */ './goodsSpecificat.vue'),
        meta: {
            authPass: true,
            title: '货物规格管理',
            noTitle: true
        }
    }
];
