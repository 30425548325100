/*
 * @Author: liukun
 * @Date: 2021-06-10 15:46:21
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-06-17 15:36:16
 * @Description: 
 */
export default [
    {
        path: '/publicPage/evaluationManagement',
        name: 'evaluationManagement',
        component: () => import(/* webpackChunkName: "common" */ './evaluationManagement.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/evaluationDetails',
        name: 'evaluationDetails',
        component: () => import(/* webpackChunkName: "common" */ './evaluationDetails.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/addEvaluation',
        name: 'addEvaluation',
        component: () => import(/* webpackChunkName: "common" */ './addEvaluation.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/orderState',
        name: 'orderState',
        component: () => import(/* webpackChunkName: "common" */ './orderState.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    }
]
