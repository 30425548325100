function deepFind(arr, activeId) {
	let res = null
	if (arr.length === 0) {
		return false
	}
	function find(list, id) {
		list.forEach(it => {
			if (it.subMenuList && it.subMenuList.length && it.subMenuList[0].type === 2) {
				state.buttonList = state.buttonList.concat(it.subMenuList)
			}
			if (it.id === id) {
				res = it
				return false
			} else if (it.children && it.children.length) {
				find(it.children, id)
			}
		})
	}
	find(arr, activeId)
	return res
}

function findButtons(list) {
	let res = []
	list.forEach(it => {
		if (it.subMenuList && it.subMenuList.length) {
			if (it.subMenuList[0].type === 2) {
				res = res.concat(it.subMenuList.map(item => item.menuId))
			} else {
				res = res.concat(findButtons(it.subMenuList))
			}
		}
	})
	return res
}
function menuRecursion(object) {
	let menuList = []
	for (const key in object) {
		if (Object.hasOwnProperty.call(object, key)) {
			const ele = object[key]
			let newNode = {
				title: ele.name,
				icon: ele.icon,
				name: ele.url,
				id: ele.menuId
			}
			if (ele.children && Array.isArray(ele.children) && ele.children.length > 0) {
				newNode.children = menuRecursion(ele.children)
			}
			menuList.push(newNode)
		}
	}
	return menuList
}

function turnMneuTreeToListPermissonFn(data, arr) {
	if (data instanceof Array && data.length > 0) {
		data.map(item => turnMneuTreeToListPermissonFn(item, arr))
	} else if(data instanceof Object) {
		/**
		 * 50701控制报表菜单权限
		 * 三个列表菜单funcCode字段和页面按钮权限字段重复，需要过滤，防止菜单配置直接影响按钮展示
		 * "申请提款"-"consig:applicationForWithdrawal"
		 * "放款管理"-"consig:loanManagement"
		 * "信息技术服务费"-"consig:InformationTechnologyServiceFee"
		 */
		if(!["consig:applicationForWithdrawal", "consig:loanManagement", "consig:InformationTechnologyServiceFee"].includes(data.funcCode)) {
			arr.push(data.funcCode)
		}
		
		if(data.children && data.children instanceof Array && data.children.length > 0) {
			data.children.map(item => turnMneuTreeToListPermissonFn(item, arr))
		}
	}
}
import roleApi from '@/services/info/role'
import { consignorService } from '@/services/consignor'

// 初始化数据
const initState = () => {
	// debugger
	return {
		guid: null,
		menuList: [],
		activeSubMenu: '0',
		activeTheme: {},
		topCurrentMenus: [],
		keepAlives: [],
		routers: [],
		routerNames: [],
		buttonList: [],
		sideStatus: true,
		currentMenus: null, // true 展开 false: 关闭,
		permissionLists: [],
		menuPermissionLists: [],
		primaryMenus: null,
		bageObj: {},
		overweightTip: null
	}
}

const state = initState()

const getters = {
	currentMenus: state => {
		return state.currentMenus
	},
	menuList(state) {
		return state.menuList
	},
	activeSubMenu(state) {
		return state.activeSubMenu
	},
	activeTheme(state) {
		return state.activeTheme
	},
	topCurrentMenus(state) {
		return state.topCurrentMenus
	},
	keepAlives(state) {
		return state.keepAlives
	},
	buttonList(state) {
		return findButtons(state.menuList)
	},
	permissionLists(state) {
		return state.permissionLists
	},
	menuPermissionLists(state) {
		return state.menuPermissionLists
	},
	primaryMenus(state) {
		return state.primaryMenus
	}
}

const mutations = {
	// 切换左侧菜单展示形式
	toggleSideBar(state) {
		state.sideStatus = !state.sideStatus
	},
	// 清空state
	clearMenuState(state) {
		state.activeMenu = '0'
	},
	setMenuList(state, list) {
		state.menuList = list
	},
	setActiveSubMenu(state, id) {
		state.activeSubMenu = id
	},
	setActiveTheme(state, theme) {
		state.activeTheme = theme
	},
	// 用于退出登录重置数据
	resetState(state) {
		Object.assign(state, initState())
	},
	setCurrentMenu(state, menu) {
		state.currentMenus = menu
		localStorage.setItem('currentMenus', JSON.stringify(menu))
	},
	setPermissionLists(state, permissionLists) {
		state.permissionLists = permissionLists
	},
	SET_MENULIST: (state, primaryMenus) => {
		state.primaryMenus = primaryMenus
		// 统计报表 将menu菜单转为权限字符串
		let arr = []
		turnMneuTreeToListPermissonFn(primaryMenus, arr)
		state.menuPermissionLists = arr
		localStorage.setItem('menuPermissionLists', arr)
	},
	setOverWeightTip: (state, tip) => {
		state.overweightTip = tip
	}
}

const actions = {
	getMenuList({ commit }, data) {
		return roleApi.listMenuVO({}).then(({ code, data }) => {
			if (code == 200) {
				commit('SET_MENULIST', data)
			}
			//
		})
		// .catch(err => {
		//
		// 	console.log(err)
		// })
	},
	selectUserPermissionList({ commit }, data) {
		return roleApi.selectUserPermissionList({}).then(({ code, data }) => {
			if (code == 200) {
				let permissionLists = data.map(item => item.perm)
				localStorage.setItem('permissionLists', permissionLists)
				commit('setPermissionLists', permissionLists)
			} else {
				console.log(code)
			}
		})
		// .catch(err => {})
	},
	getLeftMenuCornerMarker({ commit }, data) {
		return roleApi.leftMenuCornerMarker({}).then(res => {
			state.bageObj = res
		})
	},
	getOverWeightTips({ commit }) {
		consignorService.queryOverWeightTips({}).then(res => {
			console.log('超载提示:', res)
			// commit('setOverWeightTip', res.data.data)
			commit('setOverWeightTip', '您之前存在超载运单， 平台温馨提醒您今后规范操作，请勿超载！')
		})
	}
}
export default {
	namespaced: true, // 创建log的命名空间
	state,
	getters,
	mutations,
	actions
}
