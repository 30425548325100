export default [
    {
        path: '/publicPage/productionCapacity',
        name: 'productionCapacity',
        component: () => import(/* webpackChunkName: "common" */ './productionCapacity.vue'),
        meta: {
            authPass: true,
            title: '生产能力'
        }
    }, {
        path: '/publicPage/sendPlan',
        name: 'sendPlan',
        component: () => import(/* webpackChunkName: "common" */ './sendPlan.vue'),
        meta: {
            authPass: true,
            title: '发运计划'
        }
    }
];
