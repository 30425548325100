<template>
    <div class="dash-warehouse-list">
        <div class="dash-warehouse-list-banner">
            <img src="@/assets/images/home/warehouseBanner.png">
        </div>
        <div class="w-1200 dash-warehouse-list-content">
            <div class="search-items">
                <div class="item-block">
                    <span class="item-name">仓储位置</span>
                    <div class="item-container">
        
                        <a href="javascript:;" 
                        :class="{'item-link':true, 'active': item.cityCode == resourceCityCode}" 
                        v-for="(item, index) in resourceCity"
                        :key="index"
                        @click="resourceCityHandle(item)">
                            {{ item.cityName }}
                            <template v-if="item.cityCode">({{ item.cityNum }})</template>
                        </a>
                    </div>
                    <div class="item-container" v-if="resourceDis.length > 0">
                        <a href="javascript:;" 
                        :class="{'item-link':true, 'active': item.disCode == resourceDisCode}" 
                        v-for="(item, index) in resourceDis"
                        :key="index"
                        @click="resourceDisHandle(item)">
                            {{ item.disName }}
                            <template v-if="item.disCode">({{ item.disNum }})</template>
                        </a>
                    </div>
                </div>
                <div class="dash-line"></div>
                <div class="item-block">
                    <span class="item-name">仓储类型</span>
                    <div class="item-container">
                        <a href="javascript:;" :class="{'item-link':true, 'active': item.value == warehouseType}" 
                        v-for="(item) in houseCode" 
                        :key="item.key"
                        @click="typeHandle(item)">{{ item.key }}</a>
                    </div>
                </div>
                <div class="item-block">
                    <span class="item-name">仓储面积</span>
                    <div class="item-container">
                        <a 
                        href="javascript:;" 
                        :class="{'item-link':true, 'active': item.value == warehouseAcreage}" 
                        v-for="(item) in areaCode" 
                        :key="item.key"
                        @click="acreageHandle(item)">{{ item.key }}</a>
                    </div>
                </div>
                <div class="dash-line"></div>
                <div class="item-block">
                    <span class="item-name">排序方式</span>
                    <div class="item-container">
                        <a 
                        href="javascript:;" 
                        :class="{'item-link':true, 'active': item.value == sortType}" 
                        v-for="(item) in sortOptions" 
                        :key="item.key"
                        @click="sortHandle(item)">{{ item.key }}</a>
                    </div>
                </div>
                <!-- <div @click="gotoManage" class="search-items-opt">
                    进入管理
                </div> -->
            </div>
            <div class="stores-list">
                <div class="store-li" v-for="(item, index) in list" :key="index">
                    <div class="li-container">
                        <div class="left li-img">
                            <img class="view-img" :src="'https://img.zczy56.com/'+item.headImage" alt="">
                        </div>
                        <div class="left li-info">
                            <div class="li-name">
                                <span class="left name-text" @click="checkDetail(item)">{{ item.resourceName }}</span>
                                <span class="left li-type type-self">{{item.resourceManageType == 1 ? '自营' : '非自营'}}</span>
                                <div class="clear"></div>
                            </div>
                            <div class="info-row left" style="width:395px">
                                <span class="row-name">地 址：</span>
                                <span class="row-text">{{ item.resourceProvName }}{{ item.resourceCityName }}{{ item.resourceDisName }}</span>
                            </div>
                            <div class="info-row left" style="width:395px">
                                <span class="row-name">品类：</span>
                                <span class="row-text">{{ item.category }}</span>
                            </div>
                            <div class="clear"></div>
                            <div class="info-row">
                                <span class="row-name">仓库信息：</span>
                                <span class="row-text">
                                    仓库类型: 
                                    <template v-if="item.resourceType == 1">仓储</template> 
                                    <template v-if="item.resourceType == 2">运输</template>，
                                    层高：{{ item.highFloors }}，
                                    承重：{{ item.loadFloors }}，
                                    地坪材质:{{ item.floorMaterials }}，
                                    配套：{{ item.supportingFacility }}，
                                    防火：{{ item.fireProofs }}，
                                    总面积：{{ item.allTotalArea }}㎡
                                </span>
                            </div>
                            <div class="info-row">
                                <span class="row-name">仓库简介：</span>
                                <span class="row-text">{{ item.resourceIntro }}</span>
                            </div>
                            <div class="li-oper">
                                <a href="javascript:;" class="book-btn" @click="bookHandleFn(item)">在线预定</a>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="dash-line"></div>
                </div>
                <div class="list-page">
                    <el-pagination
                        background
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
            <bookStorage 
                v-if="bookShow" 
                :show="bookShow" 
                @cancel="cancelBookFn" 
                :resourceId="activeResourceId" 
                :resourceCode="activeResourceCode"
                @needLogin="showSaasLogin"
                ></bookStorage>
            <el-dialog
                title="云仓储详情"
                :visible.sync="detailDialogVisible"
                width="60%"
                append-to-body
                >
                <warehouseStorageDetail v-if="detailDialogVisible" :resourceCode="currentData.resourceCode" :resourceId="currentData.resourceId"></warehouseStorageDetail>
            </el-dialog>
            <el-dialog title="登录" :visible.sync="dialogLoginVisible" width="30%">
                <el-form :model="loginForm" :rules="rules" ref="saasLogin">
                    <el-form-item label="用户名：" label-width="100px" prop="account">
                        <el-input v-model="loginForm.account"></el-input>
                    </el-form-item>
                    <el-form-item label="密码：" label-width="100px" prop="password">
                        <el-input type="password" v-model="loginForm.password"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogLoginVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmLoginSass">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import globalService from '@/services/globalService'
import bookStorage from './bookStorage'
import { loginService } from '@/services/login';
import warehouseStorageDetail from './warehouseStorageDetail'
export default {
    components:{
        bookStorage,
        warehouseStorageDetail
    },
    data(){
        return {
            activeResourceId:'',
            activeResourceCode:'',
            resourceCity:[],
            resourceDis:[],
            houseCode:[],
            areaCode:[],
            list:[],
            bookShow:false,
            pageNum: 1,
            pageSize: 10,
            total:0,
            // 仓储位置 -市
            resourceCityCode:'',
            // 仓储位置 -区
            resourceDisCode:'',
            // 仓储类型
            warehouseType:'',
            // 仓储面积
            warehouseAcreage:'',
            // 排序类型
            sortType:'1',
            sortOptions:[
                {
                    key:'最新发布',
                    value:'1'
                },
                {
                    key:'仓库面积',
                    value:'2'
                }
            ],
            detailDialogVisible: false,
            currentData: {},
            dialogLoginVisible: false,
            loginForm: {},
            rules: {
                account: [{ required: true, message: '用户名不能为空', trigger: ['blur', 'change'] }],
                password: [{ required: true, message: '密码不能为空', trigger: ['blur', 'change'] }],
            },
        }
    },
    created(){
        // this.submitSaasLogin();
    },
    methods:{
        submitSaasLogin(){
            // trunk
            let loginParams = {
                account: 'testZD1',
                password: 'b00000000',
            }
            // 线上
            if(location.href.indexOf('zczy') !== -1){
                loginParams = {
                    account: 'demotestj1',
                    password: 'scm-2022',
                }
            }
            // 测试
            if(location.href.indexOf('172') !== -1){
                loginParams = {
                    account: 'djhuo61',
                    password: 'zc123456',
                }
            }
            let params = {
                ...loginParams,
                loginType: 0,
                from: 'saas',
                appType: 4,
                loginChannel: 1,
                busiScope: 1,
                platform: 1
            }
            loginService.submitSaasLogin(params).then(res => {
                sessionStorage.setItem('saasToken', res.data.token);
                sessionStorage.setItem('saasUser', res.data.userName);
                sessionStorage.setItem('saasUserId', res.data.userId);
            })
        },
        confirmLoginSass(){
            this.$refs.saasLogin.validate().then(() => {
                let params = {
                    ...this.loginForm,
                    loginType: 0,
                    from: 'saas',
                    appType: 4,
                    loginChannel: 1,
                    busiScope: 1,
                    platform: 1
                }
                loginService.submitSaasLogin(params).then(res => {
                    if(res.data.resultCode != '0000' || res.code != '200'){
                        this.$message.error(res.data.resultMsg || res.message)
                    }else {
                        sessionStorage.setItem('saasToken', res.data.token);
                        sessionStorage.setItem('saasUser', res.data.userName);
                        sessionStorage.setItem('saasUserId', res.data.userId);
                        this.dialogLoginVisible = false;
                        this.$nextTick(() => {
                            this.bookShow = true;
                        })
                    }
                })
            })
        },
        checkDetail(item) {
            this.currentData = item;
            this.detailDialogVisible = true;
        },
        resourceCityHandle(item) {
            // 选中状态，点击取消
            if(item.cityCode === this.resourceCityCode) {
                this.resourceCityCode = ''
            } else {
                this.resourceCityCode = item.cityCode
            }
            
            this.resourceDisCode = ''
            if(this.resourceCityCode){
                globalService.resourceLocationInfo({cityCode: this.resourceCityCode, specifyResourceServerType: 1}).then(res => {
                    if(res && res.data) {
                        this.resourceDis = [{disCode:'', disName:'无限'}].concat(res.data)
                    }
                })
            } else {
                this.resourceDis = []
            }
            this.initListFn()
        },
        resourceDisHandle(item){
            // 选中状态，点击取消
            if(item.disCode === this.resourceDisCode) {
                this.resourceDisCode = ''
            } else {
                this.resourceDisCode = item.disCode
            }
            this.initListFn()
        },
        typeHandle(item) {
            // 选中状态，点击取消
            if(item.value === this.warehouseType) {
                this.warehouseType = ''
            } else {
                this.warehouseType = item.value
            }
            this.initListFn()
        },
        acreageHandle(item) {
            // 选中状态，点击取消
            if(item.value === this.warehouseAcreage) {
                this.warehouseAcreage = ''
            } else {
                this.warehouseAcreage = item.value
            }
            this.initListFn()
        },
        sortHandle(item) {
            // 选中状态，点击取消
            if(item.value === this.sortType) {
                this.sortType = ''
            } else {
                this.sortType = item.value
            }
            this.initListFn()
        },
        initListFn(){
            let params = {
                specifyResourceServerType: 1//中资
            }
            params.pageNum = this.pageNum
            params.pageSize = this.pageSize
            // 仓储位置 -市
            if(this.resourceCityCode) {
                params.resourceCityCode = this.resourceCityCode
            }
            // 仓储位置 -区
            if(this.resourceDisCode) {
                params.resourceDisCode = this.resourceDisCode
            }
            // 仓储类型
            if(this.warehouseType) {
                params.warehouseType = this.warehouseType
            }
            // 仓储面积
            if(this.warehouseAcreage) {
                params.warehouseAcreage = this.warehouseAcreage
            }
            // 排序类型  最新发布1  仓库面积1   默认排序1
            if(this.sortType) {
                params.sortType = this.sortType
            }
            globalService.resourceWebList(params).then(res=> {
                if(res && res.data && res.data.list) {
                    this.list = res.data.list
                    this.total = res.data.total
                } else {
                    this.list = []
                }
            })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.initListFn()
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.initListFn()
        },
        bookHandleFn(item){
            if(!sessionStorage.getItem('saasToken')){
                this.$confirm('预定仓储资源，需要会员登录！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.submitSaasLogin();
                    this.loginForm = {}
                    this.dialogLoginVisible = true;
                    this.activeResourceId = item.resourceId
                    this.activeResourceCode = item.resourceCode
                });
                return;
            }
            this.activeResourceId = item.resourceId
            this.activeResourceCode = item.resourceCode
            this.bookShow = true
        },
        cancelBookFn() {
            this.bookShow = false
        },
        gotoManage(){
            location.href = location.origin + '/warehouse.html#/warehouseContract'
        },
        showSaasLogin(){
            this.bookShow = false;
            this.dialogLoginVisible = true;
        }
    },
    mounted(){
        // house_code   仓库类型 
        // area_code  仓库面积
        globalService.getDictList({code:'house_code'}).then(res => {
            if(res && res.data && res.data instanceof Array) {
                this.houseCode = [{key:'无限', value:''}].concat(res.data)
            }
        })
        globalService.getDictList({code:'area_code'}).then(res => {
            if(res && res.data && res.data instanceof Array) {
                this.areaCode = [{key:'无限', value:''}].concat(res.data)
            }
        })

        globalService.resourceLocationInfo({
            specifyResourceServerType: 1
        }).then(res => {
            if(res && res.data) {
                this.resourceCity = [{cityCode:'', cityName:'无限'}].concat(res.data)
            }
        }).catch(() => {
        })
        
        this.initListFn()
    }
}
</script>
<style lang="scss">
.dash-warehouse-list {
    .w-1200 {
        width: 1200px;
        margin: 0px auto;
    }
    .dash-warehouse-list-banner {
        img {
            width: 100vw;
        }
    }
    .dash-warehouse-list-content {
        margin-top: 16px;
    }
    .dash-line {
        border: 1px dashed #DDDDDD;
        margin-bottom: 12px;
        margin-top: 7px;
    }
    .search-items {
        background: #fff;
        box-shadow: 0px 4px 8px 0px rgba(72, 75, 143, 0.08);
        padding: 20px;
        margin-bottom: 16px;
        position: relative;
        .search-items-opt {
            position: absolute;
            right: 12px;
            top: 12px;
            height: 36px;
            background-image: url('../../assets/images/warehoue/enterContract.png');
            line-height: 36px;
            width: 100px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: center;
            cursor: pointer;
            &:hover{
                background-image: url('../../assets/images/warehoue/enterContract_active.png');
            }
        }
        .item-block {
            padding-left: 100px;
            position: relative;
            min-height: 44px;
            .item-name {
                position: absolute;
                line-height: 24px;
                font-weight: bold;
                left: 0;
                top: 10px;
                padding-left: 12px;
            }
            .item-container {
                padding-top: 10px;
                .item-link {
                    display: inline-block;
                    height: 24px;
                    line-height: 24px;
                    padding: 2px 11px;
                    margin-right: 20px;
                    margin-bottom: 12px;
                    border-radius: 12px;
                    color: #333333;
                    text-decoration: underline;
                }
                .item-link.active {
                    background: #2F6FCE;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }
    .stores-list {
        padding: 20px;
        box-shadow: 0px 4px 8px 0px rgba(72, 75, 143, 0.08);
        background: #fff;
        .li-container {
            padding:12px 10px 30px 12px;
            .view-img {
                width: 320px;
                height: 208px;
            }
            .li-info {
                margin-left: 20px;
                width: 790px;
                .li-name {
                    margin-bottom: 20px;
                    .name-text {
                        font-size: 18px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 25px;
                        cursor: pointer;
                        color: #2F6FCE;
                    }
                    .name-text:hover {
                        color: #2F6FCE;
                    }
                    .li-type {
                        height: 24px;
                        line-height: 24px;
                        padding: 2px 16px;
                        border-radius: 13px;
                        color: #fff;
                        margin-left: 10px;
                    }
                    .type-self {
                        background: #45B035;
                    }
                }
                .info-row {
                    line-height: 28px;
                    font-size: 14px;
                    .row-name {
                        display: inline-block;
                        width: 70px;
                        font-weight: bold;
                        text-align: right;
                    }
                }
                .li-oper {
                    text-align: right;
                    margin-top: 16px;
                    .book-btn {
                        width: 100px;
                        height: 36px;
                        line-height: 36px;
                        background: #0069CE;
                        border-radius: 4px;
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFFFFF;
                        display: inline-block;
                        text-align: center;
                    }
                }
            }
        }
        .list-page {
            text-align: center;
            padding-top: 30px;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #0069CE;
        }
    }
}

</style>