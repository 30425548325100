export default [
  {
    path: '/publicPage/settlementApplicationExamine',
    name: 'settlementApplicationExamine',
    component: () => import(/* webpackChunkName: "common" */ './settlementApplication.vue'),
    meta: {
      authPass: true,
      title: '',
      noTitle: true,
    },
  },
];
