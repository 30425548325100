import service from '../service';
import store from '../../store';

export default {
  // 获取模和公钥指数
  getPwdKey() {
    return service.get('/whale-aio-sso-server/app/getPwdKey');
  },

  /** ********** 登录页 *************/
  submitLogin(params) {
    return service.post('/whale-aio-sso-server/app/login', params);
  },
  submitSaasLogin(params) {
    return service.post('/uc-saas-api/saas/user/login', params);
  },

  // 系统退出
  logout(params) {
    return service.get('/whale-aio-sso-server/app/logout', params);
  },

  // 登录次数过多 是否需要图片验证
  getUserPassWordWrongTimes(params) {
    return service.post('/getUserPassWordWrongTimes', params);
  },

  captchaJpg(params) {
    return service.post('/captcha.jpg', params);
  },

  loginCheckImageVerifyCode(params) {
    return service.post('/checkImageVerifyCode', params);
  },
  // 手机登录 发送登录验证码
  sendVerifyLoginCode(params) {
    return service.post('/whale-pc/verify-code/sendVerifyLoginCode', params);
  },
  // 验证是否需要图片验证码
  isShowImageVerifyCode(params) {
    return service.post('/whale-pc/verify-code/isShowImageVerifyCode', params);
  },
  // 发送短信或语音验证码
  sendVerifyCode(params) {
    return service.post('/whale-pc/verify-code/sendVerifyCode', params);
  },
  // 验证邀请码
  checkInviteCode(params) {
    return service.post('/whale-pc/register/checkInviteCode', params);
  },
  // 注册
  registerMember(params) {
    return service.post('/whale-pc/register/registerMember', params);
  },
  // 找回密码第一步
  findPwdStep1(params) {
    return service.post('/whale-pc/password/findPwdStep1', params);
  },
  // 服务端审核验证码
  checkVerifyCode(params) {
    return service.post('/whale-pc/verify-code/checkVerifyCode', params);
  },
  // 找回密码步骤三
  findPwdStep3(params) {
    return service.post('/whale-pc/password/findPwdStep3', params);
  },
  // 计算密码等级
  countPasswordGrade(params) {
    return service.post('/whale-pc/password/countPasswordGrade', params);
  },
  // 查询车辆类型，车长字典
  queryDictByCode(params) {
    return service.post('/whale-pc/vehicle/queryDictByCode', params);
  },
  // 查询车源广场列表
  sourceOfVehicleSource(params) {
    return service.post('/whale-pc/vehicle/sourceOfVehicleSource', params);
  },
  // 新闻公告列表
  queryNewsList(params) {
    return service.post('/whale-pc/news/queryNewsList', params);
  },
  // 新闻公共详情
  queryNewsDetail(params) {
    return service.post('/whale-pc/news/queryNewsDetail', params);
  },
  // 首页第一屏幕数字
  homeShowNumbers(params) {
    return service.get('/homeShowNumbers.json', params);
  },
  // 生成二维码接口
  genLoginCode(params) {
    return service.post('/whale-pc/qr-code/gen-login-code', params);
  },
  // 二维码扫描长轮询
  loginWeb(params, configs) {
    return service.post('/whale-aio-sso-server/app/sys/loginWeb', params, configs);
  },
  // 是否第一次登录
  selectUserSpecialBackUp1(params) {
    return service.post('/whale-pc/member/selectUserSpecialBackUp1', params, {
      load: false,
    });
  },

  // 更新代注册-第一次登录
  updateUserSpecialBackUp1(params) {
    return service.post('/whale-pc/member/updateUserSpecialBackUp1', params);
  },
  // 联系人类型字典
  queryDictListByCode(params) {
    return service.post('/whale-pc/vehicle/queryDictListByCode', params);
  },
  // 下属公司
  affiliatedCompanyFrontList(params) {
    return service.post('/whale-pc/affiliatedCompanyFront/affiliatedCompanyFrontList', params);
  },
  // 获取注册协议
  queryPlateFormTreatyListNew(params) {
    return service.post('/whale-pc/member/queryPlateFormTreatyListNew', params);
  },
  queryPlateFormTreatyByContentId(params) {
    return service.post('/whale-pc/member/queryPlateFormTreatyByContentId', params);
  },
  // 提前48小时校验是否超过回单签收时间和逾期有效时间
  checkHaveBeyondSignTimeAndOverdueTime(params) {
    return service.post('/oms-consignor-pc/order/addOuterCommonOrder/checkHaveBeyondSignTimeAndOverdueTime', params);
  },
  // 补全用户类型角色  会员统一增加的
  setUpUserType(params) {
    return service.post('/whale-aio-sso-server/app/setUpUserType', params);
  },
  // 通过临时token取真实token
  getToken(params) {
    return service.post('/whale-aio-sso-server/app/getToken', params);
  },
  
};
