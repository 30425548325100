export default [
    {
        path: '/publicPage/receiptSign',
        name: 'receiptSign',
        component: () => import(/* webpackChunkName: "common" */ './receiptSign.vue'),
        meta: {
            authPass: true,
            title: '回单签收'
        }
    },
    {
        path: '/publicPage/receiptSign/detail',
        name: 'receiptSignDetail',
        component: () => import(/* webpackChunkName: "common" */ './detail.vue'),
        meta: {
            authPass: true,
            title: '回单签收详情'
        }
    }
];
