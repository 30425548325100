export default [
    {
        path: '/publicPage/youranInquiryStatisticsPage',
        name: 'youranInquiryStatisticsPage',
        component: () => import(/* webpackChunkName: "common" */ './youranInquiryStatisticsPage.vue'),
        meta: {
            authPass: true,
            title: '优然牧业询价单报价统计报表'
        }
    }
];
