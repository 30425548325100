export default [
    {
        path: '/publicPage/orderOverDue',
        name: 'orderOverDue',
        component: () => import(/* webpackChunkName: "common" */ './orderOverDue.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    }
];
