export default [
    {
        path: '/publicPage/offlineZone',
        name: 'offlineZone',
        component: () => import(/* webpackChunkName: "common" */ './offlineZone.vue'),
        meta: {
            authPass: true,
            title: '线下专区'
        }
    },
    {
        path: '/publicPage/offlineZoneDetail',
        name: 'offlineZoneDetail',
        component: () => import(/* webpackChunkName: "common" */ './offlineZoneDetail.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    },
    {
        path: '/publicPage/offlineZonePublish',
        name: 'offlineZonePublish',
        component: () => import(/* webpackChunkName: "common" */ './offlineZonePublish.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    }
];
