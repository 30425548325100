export default [
    {
        path: '/publicPage/shipperManage',
        name: 'shipperManage',
        component: () => import(/* webpackChunkName: "common" */ './shipperManagePage.vue'),
        meta: {
            authPass: true,
            title: '发货人管理'
        }
    }
];
