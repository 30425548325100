<template>
    <div>
        <div class="page-top">
            <div class="top-div">
                <div class="right">
                  <div class="right info">
                    <a href="javascript:;" @click="$router.push({name:'myFortune'})" class="mr-10"> 您好：{{ userName }}</a>
                    <a href="javascript:;" @click="loginOut" class="">[退出]</a>
                  </div>
                  <div class="clear"></div>
                </div>
                <div class="clear"></div>
            </div>
        </div>

        <template v-if="COOPERATION_NAME">
          <!-- <component v-bind:is="platform-header"></component> -->
          <platformHeader></platformHeader>
        </template>
        <template v-else>
          <div class="page-nav" v-if="channel == 'yinlun'">
              <div class="nav-div">
                  <a href="javascript:;" class="left logo">
                      <img src="@/assets/images/logo_yinlun.png" style="height:53px; margin-top:7px;" />
                  </a>
                  <div class="right nav-cont">
                      <a href="javascript:;" class="link link-member" @click="$router.push({name: 'myFortune'})" style="padding:0;">
                        <img :src="headUrl ? headUrl : '/images/portrait.png'" class="avatar-img" />
                        个人中心
                      </a>
                      <div class="clear"></div>
                  </div>
                  <div class="clear"></div>
              </div>
          </div>
          <div class="page-nav" v-else-if="channel == 'zhongqi'">
              <div class="nav-div">
                  <a href="javascript:;" class="left logo">
                      <img src="@/assets/images/logo_zhongqi.png" style="margin-top:17px;" />
                  </a>
                  <div class="right nav-cont">
                      <a href="javascript:;" class="link link-member" @click="$router.push({name: 'myFortune'})" style="padding:0;">
                        <img :src="headUrl ? headUrl : '/images/portrait.png'" class="avatar-img" />
                        个人中心
                      </a>
                      <div class="clear"></div>
                  </div>
                  <div class="clear"></div>
              </div>
          </div>
          <div class="page-nav" v-else>
              <div class="nav-div">
                  <a href="/" class="left logo">
                      <img src="/images/logo.png" style="height: 32px;margin-top: 16px;"/>
                  </a>
                  <div class="right nav-cont">
                      <a href="javascript:;" class="link link-member" @click="$router.push({name: 'myFortune'})">
                          <img src="/images/portrait.png" class="avatar-img" />
                      </a>
                      <div class="clear"></div>
                  </div>
                  <div class="clear"></div>
              </div>
          </div>
        </template>

        <div class="page-subnav">
            <div class="subnav-div">
                <div class="left links subnav-div-links">
                  <template v-for="(item, index) in links ">
                    <el-badge 
                        v-if="tipsCountNum > 0 && item.visible && item.pathName == 'messageList'" 
                        :key="index"
                        :value="tipsCountNum" 
                        :max="999">
                            <a
                                :class="{active: item.pathName === $route.name}"
                                class="link"
                                @click="clickLink(item, index)"
                                >
                                {{item.name}}
                            </a>
                    </el-badge>  
                    <a
                      v-else-if="item.visible"
                      :key="index"
                      :class="{active: item.pathName === $route.name}"
                      class="link"
                      @click="clickLink(item, index)"
                      >
                      {{item.name}}
                      </a>
                  </template>
                      <a
                      v-if="bidSwitchFlag == '1'"
                      :class="{active: pageName == 'index'}"
                      class="link"
                      href="/bid/index.html"
                      >招标平台</a>
                      <div class="left" style="position:relative">
                        <a
                        :class="{active: pageName == 'report'}"
                        class="link"
                        href="/bid/report.html"
                        >统计报表<i class="icon-new"></i></a>
                        <div class="new-tip-step" v-if="stepShow" style="width:322px; top:50px;">
                          <div class="tip-arrow"></div>
                          <div class="tip-icon"><img src="@/assets/images/custom_new.gif" style="position:absolute; top:-6px; left:-40px" /></div>
                          <div class="tip-title">统计报表搬家啦！功能更强大~</div>
                          <div class="tip-close" @click="stepHandle"></div>
                          <div class="tip-info">
                            <div class="tip-row">1、报表统计支持自定义筛选，更精简、更加个性化~</div>
                            <div class="tip-row">2、更多维度的数据统计，并且支持定制表！</div>
                          </div>
                          <div class="tip-oper">
                            <a href="javascript:;" class="btn-jump" @click="stepHandle">跳过</a>
                            <a href="javascript:;" class="btn-see" @click="stepHandle(true)">去看看</a>
                          </div>
                        </div>
                      </div>
                </div>
                <div class="right" v-if="warningFlag">
                  <div>
                    <el-popover
                        placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        content="余额过少将导致司机无法卸货，请及时转入足够金额">
                        <a href="javascript:;" class="need_recharge_warning" slot="reference" @click="clickLink({
                          pathName: 'myPay'
                        })">
                            <img src="@/assets/images/myFortune/need_recharge_warning_img.gif" alt="余额不足"/>
                            余额不足
                        </a>
                      </el-popover>
                    
                  </div>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { loginService } from '@/services/login';
import { consignorService } from '@/services/consignor';
import {mapGetters } from 'vuex';
import vipKefuJs from '@/assets/js/vipKefu'
import platformHeader from '@/views/platform/components/platformHeader.vue'
export default {
    name: 'MemberHeader',
    components:{
      'platformHeader':platformHeader
    },
    data(){
      let vm = this;
      return {
        userName:'',
        headUrl:'',
        active: '0',
        links: [{
          name: '我的',
          pathName: 'myFortune',
          documentTitle: '我的-个人中心',
          visible: true
        },{
          name: '消息中心',
          pathName: 'messageList',
          documentTitle: '消息中心',
          visible: true
        },{
          name: '智运账本',
          pathName: 'myPay',
          documentTitle: '我的-智运宝',
          visible: vm.$utils.checkPermission(['zyb:zybaccountbook'])
        }],
        // 是否有权限访问招投标 bidSwitchFlag  0否 1是
        bidSwitchFlag: '',
        stepShow: false,
        pageName: '',
        showGotoOld: false,
        // 企业定制头部
        channel:'',
        oldServicePlace: 'https://www.zczy56.com/modules/mms/system/login.html',
        COOPERATION_NAME: '',
        XINJIANG_SULE:''
      }
    },
    computed: {
      ...mapGetters('user', ['tipsCount', 'warningFlag']),
      ...mapGetters('user', ['dictConfig']),
      tipsCountNum(){
        return this.tipsCount
      }
    },
    created(){
        this.COOPERATION_NAME = COOPERATION_NAME
        this.XINJIANG_SULE = XINJIANG_SULE
        // alert(COOPERATION_NAME)
    },
    methods: {
        loginOut() {
            // 处理银轮定制 
            let obj = { name: 'Login' }
            if(this.channel && this.channel != 'null' &&  this.channel != 'undefined') {
              obj.query = {
                channel:this.channel
              }
            } else {
              this.channel = ''
              localStorage.setItem('channel', '')
            }
            // this.$router.push({ name: 'Login' })
            loginService.logout({sessionId:localStorage.getItem('tokenId')}).then(
              res => {
                if(res && res.code === '200') {

                }
                vipKefuJs.removeVipKefuData();
                this.$store.dispatch('resetState');
                localStorage.clear()
                localStorage.setItem('channel', this.channel)
                this.$router.push(obj)
              }
            ).catch(err => {
              localStorage.clear()
              localStorage.setItem('channel', this.channel)
              this.$router.push(obj);
            })
        },
        clickLink(item, index){
          if(item.pathName === 'myPay') {
            window.location.href = '/pay/index.html'
            return ''
          }
          this.$router.push({
            name: item.pathName
          })
          this.$nextTick(() => {
            this.active = index + '';
          })
          document.title = item.documentTitle;
        },
        gotoMsgCenter(tab, messageType){
          this.$router.push({
            path: '/publicPage/messageList',
            query: {
              tab: tab,
              messageType: messageType
            }
          })
        },
        initCustomStep() {
          this.stepShow = false
          consignorService.consignorOrderReportTipsFlag({}).then(res => {
            if (res.resultCode == '0000') {
              if (res.data.showFlagStep1 == '1') {
                this.stepShow = true
              }
            }
          })
        },
        stepHandle(turnBool) {
          consignorService.changeConsignorOrderReportTipsFlag({showFlagStep1: '0'}).then(res => {
            console.log(res)
            if (turnBool) {
              this.location.href = '/statics/bid/report.html'
            }
          })
          this.stepShow = false
        }
    },
    mounted(){
      let href= window.location.href;

      let jmsItemLink =  document.getElementById('jmsItemLink')
      let extraItemLink = document.getElementById('extraItemLink')

      if(href.indexOf('pre-hz') !== -1){
        this.oldServicePlace='http://pre.zczy-web.zczy.com/modules/mms/system/login.html'
        if(jmsItemLink) {
          jmsItemLink.setAttribute('href', 'http://pre.zczy-franchiser-web.zczy.com/modules/mms/system/login.html')
        }
        if(extraItemLink) {
          extraItemLink.setAttribute('href', 'http://pre.zczy-entry-web.zczy.com/modules/mms/system/login.html')
        }
      }else if(href.indexOf('hz.zczy') !== -1){
          this.oldServicePlace='https://www.zczy56.com/modules/mms/system/login.html'
      }else {
        this.oldServicePlace='http://sit.zczy-web.zczy.com/modules/mms/system/login.html'
        if(jmsItemLink) {
          jmsItemLink.setAttribute('href', 'http://sit.zczy-franchiser-web.zczy.com/modules/mms/system/login.html')
        }
        if(extraItemLink) {
          extraItemLink.setAttribute('href', 'http://sit.zczy-entry-web.zczy.com/modules/mms/system/login.html')
        }
      }


      this.userName = localStorage.getItem("userName")
      this.headUrl = localStorage.getItem("headUrl")
      this.channel = localStorage.getItem('channel')
      this.initCustomStep();
      let OLD_PC_NOT_LOGIN_SWITCH = this.dictConfig['OLD_PC_NOT_LOGIN_SWITCH'];
      this.showGotoOld = OLD_PC_NOT_LOGIN_SWITCH && OLD_PC_NOT_LOGIN_SWITCH.length ? OLD_PC_NOT_LOGIN_SWITCH[0][1] == 0 : false;
      consignorService.getUserInfo({}).then(res => {
        if (res) {
          this.bidSwitchFlag = res.bidSwitchFlag
        }
      })

      if(this.channel == 'zhongqi') {
        let liAppDom = document.querySelector('.li-app')
        if(liAppDom){
          liAppDom.style.display = 'none'
        }
      }
    }
};
</script>
<style lang="stylus" scoped>
.page-top
  font-size 12px
  width 100%
  min-width 1200px
  height 28px
  line-height 28px
  background #000C17
  .top-div
    width: 1200px
    margin: auto
    height: 28px
    line-height 28px
    .call
      color: #FFFFFF
      margin-left: 16px
    .links
      overflow: hidden
      margin-right 16px
      .li
        float: left
        color: #FFF
        position relative
        a
          color: #ffb769
          display inline-block
          padding 0 10px
        a:hover
          color: #f60
        &:after
          content ""
          position absolute
          width 1px
          height 12px
          top 8px
          right 0
          background #fff
        &:last-child:after
          display none
    .info
      color: #FFF
      a
        color #fff
        display inline-block
        margin-left 10px
.page-nav
  width: 100%
  height: 64px
  line-height: 63px
  background: #FFF
  border-bottom: 1px solid #EFF0F3
  box-shadow: 0px 1px 4px 0px #E1E6EB
  .nav-div
    width: 1200px
    margin: auto
    .logo
      overflow hidden
      height 64px
    .nav-cont
      .link
        padding 0 20px
      .link-member
        padding-right 0
        .avatar-img
          float left
          margin 20px 10px 0 0
          width 24px
          height 24px
          border-radius 12px
.page-subnav
  background:#fff
  border-bottom:1px solid #E8E8E8
  min-width: 1200px
  .subnav-div
    width: 1200px
    margin: auto
    .links
      .link
        float left
        height 54px
        line-height 54px
        display inline-block
        padding: 0 16px
      .active
        color #5086FC
  .subnav-div
    cursor pointer

</style>
<style>
.common_change_tip .common_change_tip_content .li-tag {
        height:22px; line-height:22px; background:#EF8181; color:#fff; border-radius:11px;
        position: unset;
        padding: 0 8px;
    }
.new-tip-step {
  padding: 12px;
  background: #1890FF;
  border-radius: 5px;
  box-shadow: 0 0 5px 3px rgba(0,0,0, .1);
  position:absolute;
  z-index: 999;
  line-height: 20px;
}
.new-tip-step .tip-arrow {
  position: absolute;
  top: -12px;
  left: 60px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom-color: #1890FF;
}

.new-tip-step .tip-close {
  position: absolute;
  width: 24px;
  height: 24px;
  top:0;
  right:0;
  background: url(../assets/images/step_tip_close.png) center center no-repeat;
  cursor: pointer;
}

.new-tip-step .tip-close:hover {
  opacity: .8;
}

.new-tip-step .tip-title {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  padding: 5px;
}

.new-tip-step .tip-info {
  font-size: 12px;
  color: #fff;
  padding-right: 20px;
}
.new-tip-step .tip-info .tip-row {
  padding-bottom:5px;
}
.new-tip-step .tip-oper {
  text-align: right;
  padding-top: 5px;
}
.new-tip-step .tip-oper .btn-jump {
  display: inline-block;
  padding: 0 15px;
  color: #fff;
  font-size: 12px;
}
.new-tip-step .tip-oper .btn-jump:hover {
  color: #fff;
  opacity: .8;
}
.new-tip-step .tip-oper .btn-see {
  font-size: 12px;
  display: inline-block;
  padding:0 15px;
  background: #fff;
  color: #1890FF;
  border-radius: 10px;
  line-height: 20px;
}
.new-tip-step .tip-oper .btn-see:hover {
  opacity: .8;
}

.page-subnav .subnav-div .links .link .icon-new {
  position: absolute;
  right: -10px;
  top: 5px;
  width: 24px;
  height: 14px;
  background:url(../assets/images/icon_new.png) 0 0 no-repeat;
}

.el-menu-item .icon-new,
.el-submenu .el-menu-item .icon-new {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 24px;
  height: 14px;
  background:url(../assets/images/icon_new.png) 0 0 no-repeat;
}
.goto-old-service {
    background: rgba(255,255,255,0.1600);
    border-radius: 4px;
    border: 1px solid #FFFFFF;
    width: 64px;
    width: 64px;
    height: 15px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 14px;
    text-align: center;
}
.subnav-div-links {
    display: flex;
}
.left.links.subnav-div-links  .el-badge__content.is-fixed {
    top: 9px;
    right: 18px;
} 
.left.links.subnav-div-links .el-badge{
    display: block;
}
.need_recharge_warning {
  color: #FC2100;
  height: 54px;
  line-height: 54px;
  padding-right: 18px;
}
.need_recharge_warning:hover {
  color: #FC2100;
}
.need_recharge_warning img {
  width: 20px;
  vertical-align: text-top;
}     
</style>