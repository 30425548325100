export default [
    {
        path: '/publicPage/settingContractData',
        name: 'settingContractData',
        component: () => import(/* webpackChunkName: "common" */ './settingContractData.vue'),
        meta: {
            authPass: true,
            title: '合同数据管理'
        }
    }
];
