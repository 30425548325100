export default [
    {
        path: '/publicPage/consigneeManage',
        name: 'consigneeManage',
        component: () => import(/* webpackChunkName: "common" */ './consigneeManagePage.vue'),
        meta: {
            authPass: true,
            title: '收货人管理'
        }
    }
];
