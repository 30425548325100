const state = {
	applyId: '',
	overClass: false
}

const getters = {
	applyId: state => state.applyId,
	overClass: state => state.overClass
}
const mutations = {
	SET_APPLYID: (state, applyId) => {
    // 
		state.applyId = applyId
	},
	SET_OVERCLASS: (state, overClass) => {
		state.overClass = overClass
	},
    // 用于退出登录重置数据
    resetState(state) {
        state = {
            applyId: '',
	        overClass: false
        };
    }
}

const actions = {}
export default {
	namespaced: true, // 创建log的命名空间
	state,
	getters,
	mutations
}
