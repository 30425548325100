export default[
    '/whale-pc/fileUpload/file/uploadFile',
    '/whale-merge-pc/fileUpload/file/uploadFile',
    '/oms-consignor-pc/consignor/address/saveExcel',
    '/oms-consignor-pc/settle/apply/consignor/uploadExcel',
    '/oms-consignor-pc/cargoStandard/saveExcel',
    '/oms-consignor-pc/order/importOrder/excelImportOrderStepOne',       
    '/oms-consignor-pc/order/importHugeOrder/excelImportHugeOrderStepOne',       
    '/oms-consignor-pc/order/importAdditionOrder/excelAdditionImportOrderStepOne',
    '/bigdata/business/dataCollect',
    '/oms-consignor-pc/order/addConsignorContainerHugeOrder/importContainerList'
]