/*
 * @Author: liukun
 * @Date: 2021-06-21 19:00:30
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-06-21 20:04:43
 * @Description: 
 */

export default [
    {
        path: '/publicPage/credit',
        name: 'credit',
        component: () => import(/* webpackChunkName: "common" */ './credit.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    },
    {
        path: '/publicPage/creditRule',
        name: 'creditRule',
        component: () => import(/* webpackChunkName: "common" */ './creditRule.vue'),
        meta: {
            authPass: true,
            noTitle: true
        }
    }
];
