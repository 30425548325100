<template>
  <div>
    <component v-bind:is="headName"></component>
  </div>
</template>
<script>
import xinjiang_sule_header from './xinjiang_sule_header'
import xizang_cosco_header from './xizang_cosco_header'
import zhongmian_zhiyun_header from './zhongmian_zhiyun_header'
import liangshiju_zhiyun_header from './liangshiju_zhiyun_header'
export default {
  name:'platformHeader',
  components:{
    'xinjiang_sule_header':xinjiang_sule_header,
    'xizang_cosco_header':xizang_cosco_header,
    'zhongmian_zhiyun_header':zhongmian_zhiyun_header,
    'liangshiju_zhiyun_header':liangshiju_zhiyun_header
  },
  data:()=>{
    return {
      headName:''
    }
  },
  created(){
    this.headName = COOPERATION_NAME+'_header'
  }
}
</script>