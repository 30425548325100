import service from './service'

export default {
    queryAreaConfigByCode(params) {
        return service.post('/scm-whale-pc/area/queryAreaConfigByCode', params);
    },
    resourceWebList(params) {
        return service.post('/scm-web-api/resourceWebList', params);
    },
    resourceWebInfo(params) {
        return service.post('/scm-web-api/resourceWebInfoSpecify', params);
    },
    getDictList(params) {
        return service.post('/scm-web-api/dictWeb/getDictList', params);
    },
    resourceLocationInfo(params) {
        return service.post('/scm-web-api/resourceLocationInfo', params);
    },
    resourceReserveInfo(params) {
        return service.post('/scm-web-api/resourceReserveInfo', params, {
            errHandler: false,
            headers: {
                ucSsoTokenId: sessionStorage.getItem('saasToken')
            }
        });
    },
    submitIntention(params) {
        return service.post('/scm-web-api/submitIntention', params, {
            headers: {
                ucSsoTokenId: sessionStorage.getItem('saasToken')
            }
        });
    },
      
}