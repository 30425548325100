import consignee from './consignee';
import shipper from './shipper';
import recipients from './recipients'
import transportGroup from './transportGroup'
import ruleSet from './ruleSet'
import userInsurance from './userInsurance'
import breachApplication from './breachApplication'
import consignorRouteCust from './consignorRouteCust'
import ruleListAuto from './ruleListAuto'
import jmMemberSet from './jmMemberSet'
import deficitSuperTonRule from './deficitSuperTonRule'
import relateExtraConfig from './relateExtraConfig'
import relationList from './relationList'
import contacts from './contacts'
import offlineZone from './offlineZone'
import waybillMonitoring from './waybillMonitoring';
import monitoringSettings from './monitoringSettings';
import monthlyGuarantee from './monthlyGuarantee'
import containerManage from './containerManage';
import driverBlacklist from './driverBlacklist';
import freightRateManagement from './freightRateManagement';
import setFranchiseCapacity from './setFranchiseCapacity';
import franchiseCapacityGroup from './franchiseCapacityGroup';
import settingContract from './contractData'

export default [...consignee, ...shipper,...recipients,...transportGroup,...breachApplication,...ruleSet,...userInsurance,...consignorRouteCust,...ruleListAuto,...jmMemberSet,...deficitSuperTonRule,
    ...relateExtraConfig,...relationList,...contacts, ...offlineZone, ...waybillMonitoring, ...monitoringSettings, ...monthlyGuarantee, ...containerManage, ...driverBlacklist,
    ...freightRateManagement, ...setFranchiseCapacity, ...franchiseCapacityGroup,...settingContract];
