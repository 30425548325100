export default [
    {
        path: '/publicPage/onRoadMonitored',
        name: 'onRoadMonitored',
        component: () => import(/* webpackChunkName: "common" */ './index.vue'),
        meta: {
            authPass: true,
            title: '在途监控'
        }
    }
];
