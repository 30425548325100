export default [
    {
        path: '/publicPage/myFortune',
        name: 'myFortune',
        component: () => import(/* webpackChunkName: "common" */ './myFortunePage.vue'),
        meta: {
            authPass: true,
            title: '我的',
            noTitle: true
        }
    },
    {
        path: '/publicPage/userInfo',
        name: 'userInfo',
        component: () => import(/* webpackChunkName: "common" */ './userInfo.vue'),
        meta: {
            authPass: true,
            title: '个人信息'
        }
    },
    {
        path: '/publicPage/modifyPass',
        name: 'modifyPass',
        component: () => import(/* webpackChunkName: "common" */ './modifyPass.vue'),
        meta: {
            authPass: true,
            title: '账户安全'
        }
    },
    {
        path: '/publicPage/certificationUpgrade',
        name: 'certificationUpgrade',
        component: () => import('./certificationUpgrade'),
        meta: {
            authPass: true,
            title: '认证升级'
        }
    },
    {
        path: '/publicPage/queryRemindList',
        name: 'queryRemindList',
        component: () => import('./queryRemindList'),
        meta: {
            authPass: true,
            title: '运输提醒'
        }
    },
    {
        path: '/publicPage/vipDetail',
        name: 'vipDetail',
        component: () => import('./vipDetail'),
        meta: {
            authPass: true,
            title: 'vip说明',
            noTitle: true
        }
    }
];
//
