<template>
  
  <div class="book-form">
    <el-dialog :visible.sync="show" :before-close="beforeCloseFn">
      <el-form :rules="rules" :model="bookInfo" ref="bookForm">
        <el-row>
          <el-col :span="8">
            <el-form-item label="品类" label-width="100px" class="base-item">
              {{ bookInfo.category }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最小租期" label-width="100px" class="base-item">
              {{ bookInfo.minLease }}{{ bookInfo.leaseUnit == 0 ? '年' : '个月' }}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="最大租期" label-width="100px" class="base-item">
              {{ bookInfo.maxLease }}{{ bookInfo.leaseUnit == 0 ? '年' : '个月' }}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="仓库名称" label-width="100px" class="base-item">
              {{ bookInfo.resourceName }}
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="地 址" label-width="100px" class="base-item">
              {{ bookInfo.resourcePlace }}
            </el-form-item>
          </el-col>
        </el-row>
        <div class="list-block">
          <table class="list-table">
            <tr v-for="(item, index) in bookInfo.chargeItemList">
              <th class="th-index"><span class="index-number">1</span></th>
              <th class="th th-type">服务类型</th>
              <td class="td td-type">{{ item.chargeItem }}</td>
              <th class="th th-fees">收费标准</th>
              <td class="td td-fees">{{ item.itemFees }}</td>
            </tr>
          </table>
        </div>
        <div class="block30"></div>
        <h3 class="block-title">预订信息</h3>
        <div class="block20"></div>
        <table class="simple-table">
          <thead>
            <tr>
              <th class="column-index">序号</th>
              <th class="column-build">楼栋</th>
              <th class="column-level">楼层</th>
              <th class="column-type">仓库类型</th>
              <th class="column-total-area">总面积</th>
              <th class="column-start-area">起租面积</th>
              <th class="column-book-area">预定面积</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in bookInfo.reservationList.detailList">
              <td class="column-index">{{ index+1 }}</td>
              <td class="column-build">{{ item.buildingName }}</td>
              <td class="column-level">{{ item.floorNum }}F</td>
              <td class="column-type">{{ item.warehouseTypeName }}</td>
              <td class="column-total-area">{{ item.totalArea }}</td>
              <td class="column-start-area">{{ item.rentArea }}</td>
              <td class="column-book-area">
                <el-form-item 
                :prop="'reservationList.detailList.'+index+'.intentionDetailValue'"
                >
                  <el-input
                    v-model="item.intentionDetailValue"
                    @change="validateAreaNumber(item, index)"
                    placeholder="请输入内容"
                    clearable>
                  </el-input>
                </el-form-item>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="area-count">
          <span class="count-text">合计预定面积：<b>{{ bookInfo.totalArea }}</b> 平方</span>
        </div>
        <el-form-item label="订购租期" class="enhance-item" prop="intentionTime">
          <el-date-picker
            v-model="bookInfo.intentionTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="托管品类" class="enhance-item" prop="intentionCargo">
            <el-input v-if="bookInfo.resourceManageType == 1" v-model="bookInfo.intentionCargo" placeholder="请输入" style="width: 300px;" maxlength="30" show-word-limit></el-input>
          <el-select v-else v-model="bookInfo.intentionCargo" multiple placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否需要运输服务" class="enhance-item" prop="transportServiceFlag">
            <el-radio v-model="bookInfo.transportServiceFlag" label="1">是</el-radio>
            <el-radio v-model="bookInfo.transportServiceFlag" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="是否允许混储" class="enhance-item" prop="storageFlag">
            <el-radio v-model="bookInfo.storageFlag" label="1">是</el-radio>
            <el-radio v-model="bookInfo.storageFlag" label="0">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align:center">
        <el-button type="primary" style="margin-right:70px" class="btn-orange" @click="submitBookFn">提交预定</el-button>
        <el-button class="btn-blue" @click="beforeCloseFn">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import globalService from '@/services/globalService'
export default {
  name:"bookStorage",
  props:{
    show:{
      type:Boolean,
      default:false
    },
    resourceId:{
      type:String,
      required:true
    },
    resourceCode:{
      type:String,
      required:true
    }
  },
  data(){
    return {
        showTemp: this.show,
        bookInfo: {
          reservationList:{
            detailList:[]
          }            
        },
        options: [],
        value1: [],
        radio:'',
        rules:{
          intentionTime:[
            { required: true, message: '订购租期不能为空', trigger: 'blur' }
          ],
          intentionCargo:[
            { required: true, message: '托管品类不能为空', trigger: 'blur' }
          ],
          transportServiceFlag:[
            { required: true, message: '是否需要运输服务不能为空', trigger: 'blur' }
          ],
          storageFlag:[
            { required: true, message: '是否允许混储不能为空', trigger: 'blur' }
          ]
        },
    }
  },
  watch:{
    show:function (nv, ov) {
      if(nv) {
        
      }
    }
  },
  methods:{
    submitBookFn(){
      let totalArea = this.bookInfo.totalArea
      if(totalArea && totalArea*1 >0) {
        this.$refs['bookForm'].validate(valid => {
          
          if(valid) {
            // 验证提交
            let obj = {}
            obj.resourceId = this.resourceId
            obj.resourceManageType = this.bookInfo.resourceManageType
            obj.intentionStartTime = this.bookInfo.intentionTime[0]
            obj.intentionEndTime = this.bookInfo.intentionTime[1]
            obj.intentionCargo = this.bookInfo.resourceManageType == 1 ? this.bookInfo.intentionCargo : this.bookInfo.intentionCargo.join(',')
            obj.transportServiceFlag = this.bookInfo.transportServiceFlag
            obj.storageFlag = this.bookInfo.storageFlag
            let arr = []
            this.bookInfo.reservationList.detailList.map(item => {
              if(item.intentionDetailValue) {
                let objChild = {}
                objChild.rescourceId = this.resourceId
                objChild.resourceAttributeId = item.resourceAttributeId
                objChild.resourceFloorId = item.resourceFloorId
                objChild.intentionDetailValue = item.intentionDetailValue
                arr.push(objChild)
              }
            })
            obj.detailAddList = arr
            globalService.submitIntention(obj).then(res => {
              if(res && res.code == 200) {
                this.$message.success(res.message)
                this.beforeCloseFn()
              }else {
                this.$message.error(res.msg)
              }
            }).catch(err => {
              let data = err.response.data;
              if(data.code == 401) {
                this.$confirm('预定仓储资源，需要会员登录！', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$emit('needLogin');
                }).catch(() => {
                  this.beforeCloseFn()        
                });
              }
            })

          }
        })
      } else {
        this.$message.error('预定信息中预订面积至少填1行')
      }

    },
    validateAreaNumber(item, index){
      var reg = /^\d+$/
      var intentionDetailValue = item.intentionDetailValue
      var rentArea = item.rentArea
      var totalArea = item.totalArea
      var prevStr = `第${index+1}条预定信息的`
      if(reg.test(intentionDetailValue) && intentionDetailValue > 0) {
        if(intentionDetailValue < rentArea) {
          item.intentionDetailValue = ''
          this.$message.error(prevStr+'预订面积不能小于起租面积');
        }

        if(intentionDetailValue > totalArea) {
          item.intentionDetailValue = ''
          this.$message.error(prevStr+'预订面积不能大于总面积');
        }
      } else {
        item.intentionDetailValue = ''
        this.$message.error(prevStr+'预订面积必须是大于0的正整数');
      }

      this.computerTotalAreaFn()
      // if (value === '') {
      //   callback(new Error('请输入密码'));
      // } else {
      //   if (this.ruleForm.checkPass !== '') {
      //     this.$refs.ruleForm.validateField('checkPass');
      //   }
      //   callback();
      // }
    },
    computerTotalAreaFn(){
      let totalArea = 0
      this.bookInfo.reservationList.detailList.map(item => {
        if(item.intentionDetailValue) {
          totalArea += item.intentionDetailValue*1
        } else {
          totalArea += 0
        }
      })
      this.bookInfo.totalArea = totalArea
    },
    beforeCloseFn(){
      this.$emit('cancel')
    },
    initBookFn(){
      if(!sessionStorage.getItem('saasToken')){
        this.$confirm('预定仓储资源，需要会员登录！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$emit('needLogin');
          }).catch(() => {
            this.beforeCloseFn()        
          });
          return;
      }
      let resourceId = this.resourceId
      let resourceCode = this.resourceCode
      let bookInfo = {}
      globalService.resourceReserveInfo({
        resourceId,
        resourceCode
      }).then(res => {
        if(res && res.data && res.data instanceof Object) {
            //debugger
          bookInfo = res.data
          if(bookInfo.reservationList && bookInfo.reservationList.detailList && bookInfo.reservationList.detailList instanceof Array) {
            // this.rules.reservationList = {}
            // this.rules.reservationList.detailList = []
            bookInfo.reservationList.detailList.map((item, index)=> {
              item.intentionDetailValue = ''
              // this.rules.reservationList.detailList[index] = {}
              // this.rules.reservationList.detailList[index].intentionDetailValue = [
              //   {required: true, message: '域名不能为空', trigger: 'blur'},
              //   { type: 'number', message: '预定面积必须是大于0的整数', trigger: 'blur' }
              // ]
            })
          }

          if(bookInfo.categoryList){
            let arr = []
            bookInfo.categoryList.map(item => {
              arr.push({value:item.cargoId, label:item.cargoName})
            })
            this.options = arr
          }

          bookInfo.intentionTime = ''
          // 订购开始时间
          // bookInfo.intentionStartTime = ''
          // 订购结束时间
          // bookInfo.intentionEndTime = ''
          // 预购总面积
          bookInfo.totalArea = 0
          // 意向单货物信息托管品类
          bookInfo.intentionCargo = ''
          // 是否需要运输服务0：否；1：是
          bookInfo.transportServiceFlag = ''
          // 是否允许混储0：否；1：是
          bookInfo.storageFlag = ''
          console.log(this.rules)
          
          this.bookInfo = bookInfo
        }
        
      }).catch(err => {
        let data = err.data || err.response.data;
        if(data.code == 401) {
          this.$confirm('预定仓储资源，需要会员登录！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$emit('needLogin');
          }).catch(() => {
            this.beforeCloseFn()        
          });
        }
      })
    }
  },
  mounted(){
    this.initBookFn()
  }
}
</script>
<style lang="scss">
.book-form .base-item  .el-form-item__label {font-weight: bold;}
.book-form .list-block {padding:4px 10px; background: #FBFBFB;}
.book-form .list-block .list-table {width: 100%;}
.book-form .list-block .list-table th,.book-form .list-block .list-table td {padding:12px 10px;}
.book-form .list-block .list-table .th-index {width: 30px;}
.book-form .list-block .list-table .th-index .index-number {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #2F6FCE;
  border-radius: 14px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 28px;
  text-align: center;
}
.book-form .list-block .list-table .th-fees,
.book-form .list-block .list-table .th-type {
  width: 100px;
  text-align: right;
}
.book-form .list-block .list-table .td-type {
  width: 120px;
}
.book-form .block-title {
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}

.book-form .simple-table {
  width: 100%;
  border-collapse:collapse
}
.book-form .simple-table th {
  line-height: 20px;
  padding:9px 0 7px 0;
  background: #DEEBFF;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}
.book-form .simple-table td {
  line-height: 20px;
  background: #F8F8F8;
  padding:5px 0 4px 0;
  text-align: center;
}
.book-form .simple-table td.column-index {width: 80px;}
.book-form .simple-table td.column-build {width: 80px;}
.book-form .simple-table td.column-level {width: 80px;}
.book-form .simple-table td.column-type {width: 100px;}
.book-form .simple-table td.column-total-area {width: 100px;}
.book-form .simple-table td.column-start-area {width: 100px;}
.book-form .simple-table td.column-book-area {
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}
.book-form .simple-table tbody tr {
 position: relative;
}

.book-form .simple-table tbody tr::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: #fff;
}

.book-form .simple-table .el-form-item {
  margin-bottom: 0;
}
.book-form .area-count {margin: 17px 0 31px 0; text-align: right;}
.book-form .area-count .count-text {
  display: inline-block;
  height: 22px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  
  padding-right: 30px;
  }
.book-form .area-count .count-text b {
  font-size: 16px;
  font-weight: bold;
}
.book-form .enhance-item .el-form-item__label {
  font-size: 16px;
  font-weight: bold;
}
</style>