<template>
  <div>
          <div class="page-nav">
              <div class="nav-div">
                  <a href="/platform.html" class="left logo">
                      <img src="/images/platform/zhongmian_zhiyun/member_logo.png" style="margin-top:11px" />
                  </a>
                  <div class="right nav-cont">
                      <a class="link" href="/platform.html#/sourceOfGoods">货源广场</a>
                      <a class="link" href="/consignor.html#/login">货运管家</a>
                      <a class="link" target="_blank" href="http://www.dmiscc.com/statics/bid/index.html#/noticeList">物流招标</a>
                      <a class="link" href="/platform.html#/operation">智慧运营</a>
                      <!-- <a class="link" target="_blank" href="https://www.zczy56.com/aidata/">数据大屏</a> -->
                      <a class="link" target="_blank" href="/platform.html#/bigScreen">数据大屏</a>
                      <a class="link" target="_blank" href="http://www.chncc.com/column-parent-list.html?id=5827">新闻公告</a>
                      
                      <a href="javascript:;" class="link link-member" @click="$router.push({name: 'myFortune'})">
                          <img :src="headUrl ? headUrl : '/images/portrait.png'" class="avatar-img" />
                      </a>
                      <div class="clear"></div>
                  </div>
                  <div class="clear"></div>
              </div>
          </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      headUrl:''
    }
  },
  mounted(){
    this.headUrl = localStorage.getItem("headUrl")
  }

}
</script>
<style lang="stylus" scoped>
.page-nav
  width: 100%
  height: 64px
  line-height: 63px
  background: #FFF
  border-bottom: 1px solid #EFF0F3
  box-shadow: 0px 1px 4px 0px #E1E6EB
  .nav-div
    width: 1200px
    margin: auto
    .logo
      overflow hidden
      height 64px
    .nav-cont
      .link
        padding 0 20px
        float left
      .link-member
        padding-right 0
        .avatar-img
          float left
          margin 20px 10px 0 0
          width 24px
          height 24px
          border-radius 12px
</style>