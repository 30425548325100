export default [
    {
        path: '/publicPage/orderMonitored',
        name: 'orderMonitored',
        component: () => import(/* webpackChunkName: "common" */ './index.vue'),
        meta: {
            authPass: true,
            title: '运单监控'
        }
    }
];
