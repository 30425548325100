export default [
    {
        path: '/publicPage/weightListDateManagement',
        name: 'weightListDateManagement',
        component: () => import(/* webpackChunkName: "common" */ './weightListDateManagement.vue'),
        meta: {
            authPass: true,
            title: '磅单日期管理',
        }
    }
];
