export default [
    {
        path: '/publicPage/youRanStatisticalReport',
        name: 'youRanStatisticalReport',
        component: () => import(/* webpackChunkName: "common" */ './youRanStatisticalReport.vue'),
        meta: {
            authPass: true,
            title: '优然牧业统计报表'
        }
    }
];
