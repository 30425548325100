export default [
    {
        path: '/publicPage/depositManageList',
        name: 'depositManageList',
        component: () => import(/* webpackChunkName: "common" */ './depositManageList.vue'),
        meta: {
            authPass: true,
            title: '押金管理',
        }
    }
];
//
