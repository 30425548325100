import service from '../service';
import store from '../../store';


export function getTechServiceFeeList(params) {
	return service.post('/ams/consignorMoney/consignorMoneyList', params);
}

export function findSapState(params) {
  return service.post('/ams/sap/apply/findSapState.xhtml', {
    ...params,
    load: false
  });
}
export function findJddState(params) {
    return service.post('/ams/sap/apply/findJddState.xhtml', {
      ...params,
      load: false
    });
  }

export function consignorMoneyListExport(params) {
	return service.post('/ams/consignorMoney/consignorMoneyListExport', params);
}

export function queryHaveOpen(params, otherConfig) {
	return service.post('/ams/sap/apply/queryHaveOpen', params, otherConfig);
}
