<template>
  <div
    :style="style"
    class="scrollbar-wrapper"
  >
    <el-menu
      :class="{ sideStatus: !sideStatus }"
      :collapse="false"
      :collapse-transition="false"
      :default-active="activeMenu"
      :unique-opened="true"
      @select="handleSelect"
      active-text-color="#5086FC"
      background-color="#ffffff"
      mode="vertical"
      text-color="#333"
    >
      <template v-for="route in menuList">
        <sidebar-item
          :activeId="activeMenu || activeSubMenu"
          :bageObj="bageObj"
          :item="route"
          :key="route.id"
          :nooType="account.nooType"
          v-if="route.title !== '智运宝' && route.title !== '公共功能' && !(route.title && route.title.indexOf('运费贷') !== -1 && hideShowFreightLoan) && route.title !== '统计报表' && route.title !== '招投标'"
        />
      </template>
    </el-menu>
    <!-- <div class="mirror" v-if="!bubbleIdObj.bubbleId"></div> -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { findSapState, findJddState, queryHaveOpen } from "@/services/ownerFreightloan/technicalServiceFee"
import SidebarItem from './SidebarItem'
import menus from './menu'
import roleApi from '@/services/info/role'
export default {
	components: { SidebarItem },
	data() {
		return {
			activeMenu: '',
			isCollapse: false,
			bubbleIdObj: {},
			hideShowFreightLoan: true
		}
	},
	computed: {
		...mapGetters('log', ['currentMenus', 'activeSubMenu','primaryMenus']),
		...mapState('log', ['sideStatus', 'menuList', 'bageObj']),
		...mapState('user', ['account']),
		style() {
			const width = '208px'
			return {
				width
			}
		}
	},
	watch: {
		currentMenus: {
			deep: true,
			handler(nv, ov) {
				this.setActive()
				this.getLeftMenuCornerMarker()
			}
		}
	},
	created() {
		this.getMenus()
		this.bubbleGuide()
	},
	methods: {
		...mapMutations('log', [
			'setMenuList',
			'setActiveSubMenu',
			'setPermissionLists'
		]),
		...mapActions('log', ['getLeftMenuCornerMarker']),
		
		bubbleGuide() {
			roleApi.bubbleGuide({ bubbleType: 1, businessFormat: 1 }).then(res => {
				if (res.code == 200) {
					this.$set(this.bubbleIdObj, 'bubbleId', res.data)
				}
			})
		},
		async getMenus() {
			let vm = this
			let menuList = []
			menuList = vm.menuRecursion(this.primaryMenus)
			
			// // 获取工行贷状态,京东贷状态
            // Promise.all([findJddState(),findSapState()]).then(res => {
			//     if (res[0].code == 200 || res[1].code == 200) {
            //         const data = res[0]
            //         const data1 = res[1].data
            //         if ((data1.sapApplyFlag == 1 && data1.applySapState == 8) || (data.jdApplyFlag == 1 && data.applyJdState == 2)) {
            //             // 展示
            //             this.hideShowFreightLoan = false
            //             vm.setMenuList(menuList)
            //         } else {
            //             // // 不展示
            //             // menuList = menuList.filter(item => item.title != "货主运费贷")
            //             // this.setMenuListData(menuList);
            //         }
					
			// 	} else {

			// 	}
			// })
            queryHaveOpen({}, {errHandler: false}).then(res => {
                // 展示
                this.hideShowFreightLoan = false
                vm.setMenuList(menuList)
            })
			this.setMenuListData(menuList);
		},
		setMenuListData(menuList){
			let vm = this;
			vm.setMenuList(menuList)
			let currentMenuId = null
			currentMenuId = vm.getCurrentMenuId(menuList, vm.currentMenus.name)
			if(!currentMenuId){
				currentMenuId = vm.currentMenus.id;
			}
			if (vm.currentMenus) {
				if (
					vm.currentMenus.name === 'primaryAccountNumber' &&
					vm.currentMenus.name !== 'myFortune'
				) {
					vm.$router.push({
						path: '/publicPage/primaryAccountNumber'
					})
					return
				}
				if ( vm.currentMenus.name === 'certificationUpgrade' ) {
					vm.$router.push({
						path: '/publicPage/certificationUpgrade',
						query: vm.currentMenus.query
					})
					return
				}
				if (!currentMenuId) {
					vm.$router.push({
						path: Vue.$config.home
					})
					return
				}
			}
			this.setActiveSubMenu(currentMenuId)
			this.activeMenu = currentMenuId
			// 权限
			// let res = await roleApi.selectUserPermissionList({})
			// if (res.code == 200) {
			// 	let permissionLists = res.data.map(item => item.perm)
			// 	if (permissionLists.length > 0) {
			// 		//
			// 		localStorage.setItem('permissionLists', permissionLists)
			// 		this.setPermissionLists(permissionLists)
			// 	}
			// }
			this.getLeftMenuCornerMarker()
			// } catch {
			// 	this.$message.warning('获取菜单失败，请联系管理员')
			// }
		},
		// 根据路由名字查找菜单id
		getCurrentMenuId(list, name) {
			let id = null
			list.forEach(it => {
				if (id) {
					return
				}
				if (it.name === name) {
					id = it.id
				} else if (it.children && it.children.length) {
					id = this.getCurrentMenuId(it.children, name)
				}
			})
			return id
		},
		menuRecursion(object) {
			let menuList = []
			for (const key in object) {
				if (Object.hasOwnProperty.call(object, key) ) {
					const ele = object[key]
					// 因为统计下面的统计报表无法取消，其他系统有绑定该权限，所以在代码里面过滤掉此页面
					if(ele.url != 'waybillTatistics'){
						let newNode = {
							title: ele.url == 'myFortune' ? '我的' : ele.url == 'roleManager' ? '交易平台角色管理' :  ele.name,
							icon: ele.icon,
							name: ele.url,
							id: ele.menuId
						}
						if (
							ele.children &&
							Array.isArray(ele.children) &&
							ele.children.length > 0
						) {
							newNode.children = this.menuRecursion(ele.children)
						}
						menuList.push(newNode)
					}
				}
			}
			return menuList
		},
		resetMenus(list) {
			list.forEach(it => {
				it.id = it.menuId
				// if(it.subMenuList && it.subMenuList.length && it.subMenuList[0].type !== 2){
				// 	it.children = it.subMenuList;
				// 	this.resetMenus(it.subMenuList);
				// }
			})
		},
		setActive() {
			if (this.currentMenus) {
				this.activeMenu = this.currentMenus.id
			} else {
				this.activeMenu = ''
			}
		},
		handleSelect(id, indexPath) {
			const target = this.menuList.find(it => it.id === indexPath[0])
			let currentMenu = null
			if (target) {
				if (indexPath.length === 1) {
					currentMenu = target
				} else {
					const son = this.getCurrentMenu(target.children, id)
					currentMenu = son
				}
				if (
					this.account &&
					this.account.userType === 6 &&
					currentMenu.url !== '/publicPage/myFortune' &&
					currentMenu.name !== 'myFortune'
				) {
					this.$router.push('/publicPage/primaryAccountNumber')
				} else {
					if (currentMenu.url) {
						this.$router.push(currentMenu.url)
					} else {
						this.$router.push({
							name: currentMenu.name
						})
					}
				}
				if(this.activeMenu === currentMenu.id){
					this.$emit('on-reload')
				}
				this.activeMenu = currentMenu.id
				this.setActiveSubMenu(currentMenu.id)
			}
		},
		// 根据菜单id查找路由
		getCurrentMenu(list, id) {
			let son = null
			list.forEach(it => {
				if (son) {
					return
				}
				if (it.id === id) {
					son = it
				} else if (it.children && it.children.length) {
					son = this.getCurrentMenu(it.children, id)
				}
			})
			return son
		}
	},
	provide() {
		return {
			bubbleIdObj: this.bubbleIdObj
		}
	}
}
</script>

<style lang="scss">
.scrollbar-wrapper {
	transition: width 0.2s;
	height: 100%;
	width: 170px;
	background: #fff;
	box-shadow: 2px 0px 7px 0px rgba(48, 48, 82, 0.1);
	.sideStatus {
		.el-submenu__icon-arrow {
			display: none;
		}
	}

	.el-menu {
		height: 100%;
	}
	/deep/.el-menu-item {
		padding-left: 12px;
	}

	.el-menu-item,
	.el-submenu {
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		min-width: auto !important;
		img {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
	}
	.el-menu-item,
	.el-submenu__title {
		&:hover {
			background-color: #e7eeff !important;
			border-right: 2px solid #5086fc;
		}
	}
	.el-menu-item.is-active {
		background-color: #e7eeff !important;
		border-right: 2px solid #5086fc;
	}
}
.mirror {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10;
}
</style>
