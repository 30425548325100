export default [
    {
        path: '/publicPage/receiptConfirmation',
        name: 'receiptConfirmation',
        component: () => import(/* webpackChunkName: "common" */ './receiptConfirmation.vue'),
        meta: {
            authPass: true,
            title: '回单确认'
        }
    }
];