import axios from 'axios';
import {
  Message
} from 'element-ui';
import {
  isArguments
} from 'lodash';
const qs = require('qs');

const service = {
  successHandler(res) {
    if (res.status === 200) {
      if (res.data == null && (res.config.responseType === 'JSON' || res.config.responseType === 'json') && res.request.responseText != null) {
        try {
          res.data = JSON.parse(res.request.responseText);
        } catch (e) {
          console.log(e)
        }
      }
      return res.data;
    }
    return {};
  },
  errHandler(err, {
    errHandler = true
  }) {
    if(err.response){
      err = err.response
    }
    if (errHandler && err.code && err.code !== 'AIO-401' && err.status !== 401) {
      if (err.data) {
        if(err.noErrorTip){
          return err;
        }
        Message.error(err.data.resultMsg || err.message || '接口调用异常');
      } else {
        // 回单确认queryOrderExceptionNotToReceiptFlag特殊处理
        if (err.code == 300 && err.msg === '运单状态异常，是否继续操作确认?' ) {
          return
        } else {
            if(err.noErrorTip){
                return err;
            }
            Message.error(err.msg || err.message || '接口调用异常');
        }
      }
    }else if(err.status && err.status != 200){
      Message.error('接口调用异常', err.status + (err.message || ''));
    }

    return Promise.reject(err);
  },
  get(url, options, otherConfig = {}) {
    return axios
      .get(url, {
        params: options,
        ...otherConfig
      })
      .then(this.successHandler)
      .catch(err => this.errHandler(err, otherConfig));
  },
  post(url, options, otherConfig = {}) {
    return axios
      .post(url, options, {
        'Content-Type': 'application/json',
        ...otherConfig
      })
      .then(this.successHandler)
      .catch(err => this.errHandler(err, otherConfig));
  },
  delete(url, options, otherConfig = {}) {
    return axios
      .delete(url, {
        data: options,
        ...otherConfig
      })
      .then(this.successHandler)
      .catch(err => this.errHandler(err, otherConfig));
  },
  deleteParams(url, options, otherConfig = {}) {
    return axios
      .delete(url, {
        params: options,
        paramsSerializer: params => {
          return qs.stringify(params, {
            indices: false
          });
        },
        ...otherConfig
      })
      .then(this.successHandler)
      .catch(err => this.errHandler(err, otherConfig));
  },
  put(url, options, otherConfig = {}) {
    return axios
      .put(url, options, otherConfig)
      .then(this.successHandler)
      .catch(err => this.errHandler(err, otherConfig));
  },
  postExport(url, param) {
    return axios
      .post(url, param, {
        responseType: 'blob'
      })
      .then(response => {
        const newfileName = response.headers['content-disposition'] ? response.headers['content-disposition'].split('=')[1] : param ?param.fileName : '文件';
        const decodeName = decodeURI(newfileName);
        if ('download' in document.createElement('a')) {
          const aLink = document.createElement('a');
          let blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.setAttribute('download', `${decodeName}`);
          aLink.click();
          window.URL.revokeObjectURL(blob);
        } else {
          // IE10+下载
          let blob = new Blob([response.data]); // 这个content是下载的文件内容
          navigator.msSaveBlob(blob, decodeName);
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  getPDFExport(url, param) {
    return axios
      .get(
        url, {
          params: param,
          responseType: 'blob'
        }
      )
      .then(response => {
        const newfileName = response.headers['content-disposition'].split('=')[1];
        const decodeName = decodeURI(newfileName);
        if ('download' in document.createElement('a')) {
          const aLink = document.createElement('a');
          let blob = new Blob([response.data], { type: 'application/pdf' });
          aLink.href = URL.createObjectURL(blob);
          aLink.setAttribute('download', `${decodeName}`);
        //   aLink.setAttribute('target', `_black`);
          aLink.click();
          window.URL.revokeObjectURL(blob);
        } else {
          // IE10+下载
          let blob = new Blob([response.data]); // 这个content是下载的文件内容
          navigator.msSaveBlob(blob, decodeName);
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  getExport(url, param) {
    return axios
      .get(
        url, {
          params: param,
          responseType: 'blob'
        }
      )
      .then(response => {
        const newfileName = response.headers['content-disposition'].split('=')[1];
        const decodeName = decodeURI(newfileName);
        if ('download' in document.createElement('a')) {
          const aLink = document.createElement('a');
          let blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.setAttribute('download', `${decodeName}`);
          aLink.click();
          window.URL.revokeObjectURL(blob);
        } else {
          // IE10+下载
          let blob = new Blob([response.data]); // 这个content是下载的文件内容
          navigator.msSaveBlob(blob, decodeName);
        }
      })
      .catch(error => {
        console.error(error);
      });
  },
  postExcelExport(url, param) {
    return axios
      .post(url, param, {
        responseType: 'blob'
      })
      .then(response => {
        const decodeName = decodeURI(param.fileName);
        if ('download' in document.createElement('a')) {
          const aLink = document.createElement('a');
          let blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          });
          aLink.href = URL.createObjectURL(blob);
          aLink.setAttribute('download', `${decodeName}`);
          aLink.click();
          window.URL.revokeObjectURL(blob);
        } else {
          // IE10+下载
          let blob = new Blob([response.data]); // 这个content是下载的文件内容
          navigator.msSaveBlob(blob, decodeName);
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
};

export default service;