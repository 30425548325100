import config from '@/assets/utils/config';

const router = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/publicPage/login/login.vue'),
        meta: {
            authPass: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/publicPage/login/register/register.vue'),
        meta: {
            authPass: true
        }
    },
    {
        path: '/findPassword',
        name: 'findPassword',
        component: () => import('@/views/publicPage/login/forget/forgetPassWord.vue'),
        meta: {
            authPass: true
        }
    },
    {
        path: '/emptPage',
        name: 'emptPage',
        component: () => import('./emptPage.vue'),
        meta: {
            authPass: true
        }
    },
    {
        path: '/',
        redirect: () => {
            let home = config[process.env.NODE_ENV].home;
            let query = {};

            home = home.split('?');

            if (home.length > 1) {
                let params = home[1].split('&');

                params.forEach(item => {
                    let param = item.split('=');

                    if (param.length > 1) {
                        query[param[0]] = param[1];
                    } else {
                        query[param[0]] = '';
                    }
                });

                return { path: home[0], query };
            } else {
                return home[0];
            }
        }
    },
    {
        path: '/401',
        name: 'error.401',
        component: () => import(/* webpackChunkName: "common" */ './error'),
        meta: {
            authPass: true
        }
    },
    {
        path: '*',
        name: 'error.404',
        component: () => import(/* webpackChunkName: "common" */ './error'),
        meta: {
            authPass: true
        }
    },
    {
        path: '/500',
        name: 'error.500',
        component: () => import(/* webpackChunkName: "common" */ './error'),
        meta: {
            authPass: true
        }
    }
];

export default router;
