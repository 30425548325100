<template>
    <div class="warehouseStorageDetail">
        <div class="stores-list">
            <div class="store-li">
                <div class="li-container">
                    <div class="left li-info">
                        <div class="li-name">
                            <span class="left name-text">{{ resourceBasicParam.resourceName }}</span>
                            <span class="left li-type type-self">{{resourceBasicParam.resourceManageType == 1 ? '自营' : '非自营'}}</span>
                            <div class="clear"></div>
                        </div>
                        <div class="info-row left" style="width:395px">
                            <span class="row-name">地 址：</span>
                            <span class="row-text">{{ resourceBasicParam.resourceProvName }}{{ resourceBasicParam.resourceCityName }}{{ resourceBasicParam.resourceDisName }}</span>
                        </div>
                        <div class="info-row left" style="width:395px" v-if="false">
                            <span class="row-name">品类：</span>
                            <span class="row-text">钢材、煤炭、黄沙</span>
                        </div>
                        <div class="info-row left" style="width:395px">
                            <span class="row-name">园区安保：</span>
                            <span class="row-text">{{ resourceBasicParam.resourceSecurityName }}</span>
                        </div>
                        <div class="clear"></div>
                        <div class="info-row" v-if="false">
                            <span class="row-name">仓库信息：</span>
                            <span class="row-text">仓库类型，层高，称重，地坪材质，配套，防火，总面积5000㎡</span>
                        </div>
                        <div class="info-row">
                            <span class="row-name">仓库简介：</span>
                            <span class="row-text">{{ resourceBasicParam.resourceIntro }}</span>
                        </div>
                        <div class="li-oper">
                            <a href="javascript:;" class="book-btn" @click="bookHandleFn">在线预定</a>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="block30"></div>
        <div class="storage-detail">
            <div class="detail-tabs">
                <a href="javascript:;" @click="() => { activeTab = 'build' }" :class="{'tab-link':true, 'left':true, 'active': activeTab == 'build'}">仓库信息</a>
                <a href="javascript:;" @click="() => { activeTab = 'fees' }" :class="{'tab-link':true, 'left':true, 'active': activeTab == 'fees'}">收费标准</a>
                <div class="clear"></div>
            </div>

            <div class="build-info" v-show="activeTab == 'build'">
                <div class="bulid-li-container" v-for="(item,index) in resourceBuildingParamList" :key="index">
                    <div class="build-li">
                        <div class="li-row">
                            <span class="row-name">楼栋名称</span>
                            <div class="row-cont">{{ item.buildingName }}</div>
                        </div>
                        <div class="li-row">
                            <span class="row-name">配套设施</span>
                            <div class="row-cont">{{ item.supportingFacilityName }}</div>
                        </div>
                        <div class="li-row build-images">
                            <span class="row-name">楼栋图片</span>
                            <div class="row-cont">
                                <el-image v-for="url in item.buildImgs" :key="url" :src="url" lazy :preview-src-list="item.buildImgs" class="li-img" :lazy="true"></el-image>
                                <div class="clear"></div>
                            </div>
                        </div>
                        <div class="li-row">
                            <span class="row-name">楼      层</span>
                            <div class="row-cont" v-if="item.resourceFloorParamList">
                                <div class="build-count">共{{ item.resourceFloorParamList.length }}层</div>
                                <div class="bulid-level" v-if="item.show">
                                    <div class="level-li" v-for="(levelItem, levelIndex) in item.resourceFloorParamList" :key="levelIndex">
                                        <div class="level-index">
                                            <span class="line"></span>
                                            <span class="level-number">{{ levelIndex+1 }}F</span>
                                            <span class="foot-dot"></span>
                                        </div>
                                        <div class="level-row">
                                            <div class="level-item">
                                                <span class="item-name">仓库类型</span>
                                                <div class="item-text">{{ levelItem.warehouseTypeName }}</div>
                                            </div>
                                            <div class="level-item">
                                                <span class="item-name">总面积</span>
                                                <div class="item-text">{{ levelItem.totalArea }} m²</div>
                                            </div>
                                            <div class="level-item">
                                                <span class="item-name">起租面积</span>
                                                <div class="item-text">{{ levelItem.rentArea }} m²</div>
                                            </div>
                                            <div class="level-item">
                                                <span class="item-name">层高</span>
                                                <div class="item-text">{{ levelItem.highFloor }}</div>
                                            </div>
                                        </div>
                                        <div class="level-row">
                                            <div class="level-item">
                                                <span class="item-name">承      重</span>
                                                <div class="item-text">{{ levelItem.loadFloor }}</div>
                                            </div>
                                            <div class="level-item">
                                                <span class="item-name">地坪材质</span>
                                                <div class="item-text">{{ levelItem.material }}</div>
                                            </div>
                                        </div>
                                        <div class="level-row">
                                            <div class="level-images">
                                                <el-image v-for="url in levelItem.floorImageArr" :key="url" :src="url" lazy :preview-src-list="levelItem.floorImageArr" class="level-img" :lazy="true"></el-image>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="li-oper">
                            <el-button type="primary" @click="toggleBuildHandle(item)">
                                <template v-if="!item.show">展开</template>
                                <template v-else>收起</template>
                            </el-button>
                        </div>
                    </div>
                    <div class="block30"></div>
                </div>
            </div>

            <div class="fees-info" v-show="activeTab == 'fees'">
                <div class="fees-head">
                    <div class="fees-item left">
                        <span class="item-name">品类</span>
                        <div class="item-text">{{ resourceChargeParam.categoryListName }}</div>
                    </div>
                    <div class="fees-item left">
                        <span class="item-name">最小租期</span>
                        <div class="item-text">{{ resourceChargeParam.minLease }}
                            <template v-if="resourceChargeParam.leaseUnit == 0">年</template>
                            <template v-if="resourceChargeParam.leaseUnit == 1">个月</template>
                        </div>
                    </div>
                    <div class="fees-item left">
                        <span class="item-name">最大租期</span>
                        <div class="item-text">{{ resourceChargeParam.maxLease }}
                            <template v-if="resourceChargeParam.leaseUnit == 0">年</template>
                            <template v-if="resourceChargeParam.leaseUnit == 1">个月</template>
                        </div>
                    </div>
                    <div class="clear"></div>
                </div>
                <div class="fees-list">
                    <div class="fees-li" v-for="(feeItem, feeIndex) in resourceChargeParam.serverParamList" :key="feeIndex">
                        <div class="li-index">{{ feeIndex+1 }}</div>
                        <div class="li-item li-type">
                            <span class="item-name">服务类型</span>
                            <div class="item text">{{ feeItem.serverName }}</div>
                        </div>
                        <div class="li-item li-price">
                            <span class="item-name">收费标准</span>
                            <div class="item text">{{ feeItem.chargeName }}</div>
                        </div>
                    </div>
                </div>
                <div class="fees-foot">
                    <span class="foot-tip">实际收费内容和价格以合同签订为准</span>
                    <div class="block20"></div>
                    <a href="javascript:;" class="book-btn" @click="bookHandleFn">在线预定</a>
                </div>
            </div>
            
        </div>
        <bookStorage v-if="bookShow" :show="bookShow" @cancel="cancelBookFn" :resourceId="resourceId" :resourceCode="resourceCode"></bookStorage>
    </div>
</template>
<script>
import globalService from '@/services/globalService'
import bookStorage from './bookStorage'
export default {
    props: ['resourceCode', 'resourceId'],
    components:{
        bookStorage
    },
    data(){
        return {
            activeTab:'build',
            bookShow:false,
            buildImgs:[
                // 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/1/34/19aa98b1fcb2781c4fba33d850549jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/0/6f/e35ff375812e6b0020b6b4e8f9583jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/9/bb/e27858e973f5d7d3904835f46abbdjpeg.jpeg',
                // 'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/3/28/bbf893f792f03a54408b3b7a7ebf0jpeg.jpeg',
                // 'https://fuss10.elemecdn.com/2/11/6535bcfb26e4c79b48ddde44f4b6fjpeg.jpeg'
            ],
            // 基础信息
            resourceBasicParam:{
                
            },
            // 楼栋信息
            resourceBuildingParamList:[],
            // 收费标准
            resourceChargeParam:{},
            // 联系信息
            resourceContactParam:{},
            // 收发货地址
            resourceAddressParamList:[],
            serverParamList:[]
        }
    },
    methods:{
        toggleBuildHandle(item){
            item.show = !item.show
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        bookHandleFn(){
            this.bookShow = true
        },
        cancelBookFn() {
            this.bookShow = false
        }
    },
    mounted(){
        let {resourceCode, resourceId} = this;
        globalService.resourceWebInfo({resourceCode, resourceId, specifyResourceServerType: 1}).then(res => {
            if(res && res.data) {
                this.resourceBasicParam = res.data.resourceBasicParam ? res.data.resourceBasicParam : {}
                if(this.resourceBasicParam.firstImage) {
                    this.resourceBasicParam.firstImage = 'https://img.zczy56.com/'+ this.resourceBasicParam.firstImage
                }
                let resourceBuildingParamList = res.data.resourceBuildingParamList ? res.data.resourceBuildingParamList : []
                // 楼栋图片
                if(resourceBuildingParamList.length > 0) {
                    resourceBuildingParamList.map((item, index) => {
                        if(index >0) {
                            item.show = false
                        } else {
                            item.show = true
                        }
                        if(item.buildingImage){
                            let arr = item.buildingImage.split()
                            let _arr = arr.map(item => {
                                return 'https://img.zczy56.com/'+item
                            })
                            item.buildImgs = _arr
                            // 楼栋-楼层图片
                            if(item.resourceFloorParamList) {
                                item.resourceFloorParamList.map( levelItem => {
                                    if(levelItem.floorImage) {
                                        levelItem.floorImageArr = levelItem.floorImage.split()
                                        let _floorImageArr = levelItem.floorImageArr.map(floorImg => {
                                             return 'https://img.zczy56.com/'+floorImg
                                        })
                                        levelItem.floorImageArr = _floorImageArr
                                        
                                    } else {
                                        levelItem.floorImageArr = []
                                    }
                                })
                            }
                        } else {
                            item.buildImgs = []
                        }
                    })
                    
                }
                this.resourceBuildingParamList = resourceBuildingParamList
                this.resourceChargeParam = res.data.resourceChargeParam ? res.data.resourceChargeParam : {}
                this.resourceContactParam = res.data.resourceContactParam ? res.data.resourceContactParam : null
                this.resourceAddressParamList = res.data.resourceAddressParamList ? res.data.resourceAddressParamList : null
                
            }
        })
    } 
}
</script>
<style lang="scss">
.warehouseStorageDetail {
    .dash-line {
    border: 1px dashed #DDDDDD;
}
.search-items {
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(72, 75, 143, 0.08);
    padding: 20px;
    .item-block {
        padding-left: 100px;
        position: relative;
        .item-name {
            position: absolute;
            line-height: 24px;
            font-weight: bold;
            left: 0;
            top: 10px;
            padding-left: 12px;
        }
        .item-container {
            padding-top: 10px;
            .item-link {
                display: inline-block;
                height: 24px;
                line-height: 24px;
                padding: 0 11px;
                margin-right: 20px;
                margin-bottom: 10px;
                border-radius: 12px;
            }
            .item-link.active {
                background: #2F6FCE;
                color: #fff;
            }
        }
    }
}
.stores-list {
    padding: 20px;
    background: #fff;
    .li-container {
        padding:12px 10px 30px 12px;
        .view-img {
            width: 320px;
            height: 208px;
        }
        .li-info {
            margin-left: 20px;
            width: 790px;
            .li-name {
                margin-bottom: 20px;
                .name-text {
                    font-size: 18px;
                    font-weight: 500;
                    color: #333333;
                    line-height: 25px;
                }
                .li-type {
                    height: 24px;
                    line-height: 24px;
                    padding:0 14px;
                    border-radius: 12px;
                    color: #fff;
                    margin-left: 10px;
                }
                .type-self {
                    background: #7FB336;
                }
            }
            .info-row {
                line-height: 28px;
                font-size: 14px;
                .row-name {
                    display: inline-block;
                    width: 70px;
                    font-weight: bold;
                    text-align: right;
                }
            }
            .li-oper {
                text-align: right;
                margin-top: 16px;
                .book-btn {
                    width: 100px;
                    height: 36px;
                    line-height: 36px;
                    background: #ED7C29;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 500;
                    color: #FFFFFF;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }
    .list-page {
        text-align: center;
        padding-top: 30px;
    }
}

.storage-detail {
    .detail-tabs {
        .tab-link {
            width: 50%;
            height: 38px;
            line-height: 38px;
            background: #D2DEF0;
            text-align: center;
            font-size: 16px;
            font-weight: bold;
            color: #7B9BCA;
        }
        .active {
            background: #3875D0;
            color: #fff;
        }
    }
    .build-info {
        .build-li {
            background: #FCFCFC;
            box-shadow: 0px 4px 8px 0px rgba(72, 75, 143, 0.08);
            padding:30px;
            .li-row {
                position: relative;
                padding-left: 110px;
                margin-bottom: 20px;
                min-height: 20px;
                line-height: 20px;
                .row-name {
                    position: absolute;
                    left: 0;
                    line-height: 20px;
                    font-weight: bold;
                }
                .li-img {
                    width: 160px;
                    height: 90px;
                    float: left;
                    margin: 0 20px 15px 0;
                }
                .build-count {
                    line-height: 20px;
                    margin-bottom: 4px;
                }
                .bulid-level {
                    .level-li {
                        position: relative;
                        border-bottom: 1px dashed #ddd;
                        padding-top: 20px;
                        .level-index {
                            position: absolute;
                            top: 0;
                            left: -110px;
                            bottom: 0;
                            width: 100px;
                            .line {
                                position: absolute;
                                width: 4px;
                                background: #2F6FCE;
                                border-radius: 10px;
                                top: 25px;
                                left: 50%;
                                // height: 100%;
                                bottom:-25px;
                                margin-left: -2px;
                            }
                            .foot-dot {
                                position: absolute;
                                left: 50%;
                                bottom: -35px;
                                width: 10px;
                                height: 10px;
                                background: #2F6FCE;
                                border-radius: 5px;
                                margin-left: -5px;
                            }
                            .level-number {
                                position: absolute;
                                width: 33px;
                                height: 33px;
                                line-height: 33px;
                                text-align: center;
                                color: #fff;
                                background: #2F6FCE;
                                top: 20px;
                                left: 50%;
                                margin-left: -17px;
                                border-radius: 50%;
                                font-size: 16px;
                            }
                        }
                    }
                    .level-li:last-child {
                        .level-index {
                            .line {
                                bottom:5px;
                            }
                            .foot-dot {
                                bottom: 0;
                            }
                        }
                    }
                    .level-row {
                        margin-bottom: 20px;
                    }
                    .level-item {
                        display: inline-block;
                        width: 185px;
                        position: relative;
                        padding-left: 70px;
                        min-height: 20px;
                        line-height: 20px;
                        .item-name {
                            line-height: 20px;
                            position: absolute;
                            left:0;
                            font-weight: bold;
                        }
                    }
                    .level-images {
                        .level-img {
                            width: 80px;
                            height: 45px;
                            margin: 0 12px 10px 0;
                        }
                    }
                }
            }
            .build-images {
                margin-bottom: 5px;
            }
            .li-oper {
                text-align: right;
            }
        }
    }
    .fees-info {
        padding: 30px 50px 30px 50px;
        background: #FCFCFC;
        box-shadow: 0px 4px 8px 0px rgba(72, 75, 143, 0.08);
        .fees-head {
            .fees-item {
                position: relative;
                width: 270px;
                padding-left: 80px;
                margin-right: 10px;
                .item-name {
                    position: absolute;
                    width: 80px;
                    left: 0;
                    top: 0;
                    line-height: 20px;
                    font-weight: bold;
                }
            }
        }
        .fees-list {
            margin-top: 30px;
            border-top: 1px dashed #ddd;
            .fees-li {
                display: flex;
                padding: 20px 0 15px 0;
                border-bottom: 1px dashed #ddd;
                .li-index {
                    width: 28px;
                    height: 28px;
                    line-height: 28px;
                    background: #2F6FCE;
                    text-align: center;
                    color: #fff;
                    margin-right: 54px;
                    border-radius: 50%;
                }
                .li-item {
                    position: relative;
                    padding-left: 70px;
                    line-height: 20px;
                    min-height: 20px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                    .item-name {
                        position: absolute;
                        width: 70px;
                        left: 0;
                        top: 4px;
                        font-weight: bold;
                    }
                }
                .li-type {
                    width: 280px;
                    margin-right: 16px;
                }
            }
        }
        .fees-foot {
            text-align: right;
            .foot-tip {
                color: #FF4444;
                font-size: 12px;
                display: inline-block;
                line-height: 17px;
                margin-top: 4px;
            }
            .book-btn {
                width: 100px;
                height: 36px;
                line-height: 36px;
                background: #ED7C29;
                border-radius: 4px;
                font-size: 16px;
                font-weight: 500;
                color: #FFFFFF;
                display: inline-block;
                text-align: center;
            }
        }
    }
}

}

</style>