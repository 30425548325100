export default [
    {
        path: '/publicPage/myWaybill',
        name: 'myWaybill',
        component: () => import(/* webpackChunkName: "common" */ './myWaybill.vue'),
        meta: {
            authPass: true,
            title: '我的运单'
        }
    },
    {
        path: '/publicPage/myWaybillDetail',
        name: 'myWaybillDetail',
        component: () => import(/* webpackChunkName: "common" */ './myWaybillDetail.vue'),
        meta: {
            authPass: true,
            title: '我的运单详情',
            noTitle: true
        }
    },
    {
        path: '/publicPage/myHugeWaybillDetail',
        name: 'myHugeWaybillDetail',
        component: () => import(/* webpackChunkName: "common" */ './myHugeWaybillDetail.vue'),
        meta: {
            authPass: true,
            title: '批量货运详情',
            noTitle: true
        }
    },
    {
        path: '/publicPage/breachApplyTable',
        name: 'breachApplyTable',
        component: () => import(/* webpackChunkName: "common" */ './mywaybillCom/breachApplyTable.vue'),
        meta: {
            authPass: true,
            title: '违约申请',
            noTitle: true
        }
    },
    {
        path: '/publicPage/batchOrderSelectCarrier',
        name: 'batchOrderSelectCarrier',
        component: () => import(/* webpackChunkName: "common" */ './batchOrderSelectCarrier.vue'),
        meta: {
            authPass: true,
            title: '批量选择承运',
            noTitle: true
        }
    }
];
