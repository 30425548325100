
// 初始化数据
const initState = () => {
    return {
       defaultData: {},
       currentData: {}
    };
};

const state = initState();

const getters = {
    defaultData: state => {
        return state.defaultData;
    },
    currentData: state => {
        return state.currentData;
    }
};

const mutations = {
    setDefaultData(state, data) {
        state.defaultData = data;
    },
    setCurrentData(state, data) {
        state.currentData = data;
    },
    // 用于退出登录重置数据
    resetState(state) {
        Object.assign(state, initState());
    }
};
export default {
    namespaced: true, // 创建log的命名空间
    state,
    getters,
    mutations
};
