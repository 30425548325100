export default [
    {
        path: '/publicPage/invoiceApplicationCount',
        name: 'invoiceApplicationCount',
        component: () => import(/* webpackChunkName: "common" */ './invoiceApplicationCountPage.vue'),
        meta: {
            authPass: true,
            title: '发票申请统计'
        }
    }
];
