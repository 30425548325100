export default [
    {
        path: '/publicPage/breachManegement',
        name: 'breachManegement',
        component: () => import(/* webpackChunkName: "common" */ './breachManegement.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    },
    {
        path: '/publicPage/breachDetail',
        name: 'breachDetail',
        component: () => import(/* webpackChunkName: "common" */ './breachDetail.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    },
    {
        path: '/publicPage/toBreachList',
        name: 'toBreachList',
        component: () => import(/* webpackChunkName: "common" */ './toBreachList.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    },
    {
        path: '/publicPage/breachContractDetail',
        name: 'breachContractDetail',
        component: () => import(/* webpackChunkName: "common" */ './breachContractDetail.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    }
];
