<template>
  <div class="side-bar-item">
    <template v-if="!item.children||InvoiceStatements.includes(item.title)">
      <el-menu-item :index="item.id">
        <svg-icon :icon-class="item.icon || ''"></svg-icon>       
          <span slot="title" v-if="hasBage(item) && bage[item.name]">
             <el-badge
              :value="bage[item.name]"
              class="item"
            >
            {{ item.title}}
            </el-badge>
          </span>
        <span
          slot="title"
          v-else-if="isDepMenu(item)"
        >
          <span>{{ item.title }}</span>
          <span class="newIcon">新</span>
          <el-popover
            placement="right"
            trigger="manual"
            v-model="depVisible"
            width="400"
          >
            <div>
              <el-row :gutter="10">
                <el-col :span="6">
                  <img
                    alt
                    src="@/assets/images/menu/pop1.png"
                  />
                </el-col>
                <el-col :span="18">
                  <div class="popTit">【部门管理】板块</div>
                  <div class="popContent">
                    您可以根据实际需要对公司组织架构部门进行新增、删除和编辑操作，
                    <span>如需接下来配置【用户管理】权限必须关联归属部门</span>
                  </div>
                  <div class="popButton">
                    <el-button
                      @click="cancels"
                      class="blackButton"
                      type="text"
                    >跳过</el-button>
                    <el-button
                      @click="nextTo(item)"
                      type="text"
                    >继续探索</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <span slot="reference"></span>
          </el-popover>
        </span>
        <span
          slot="title"
          v-else-if="isRoleMenu(item)"
        >
          <span>{{ item.title }}</span>
          <span class="newIcon">新</span>
          <el-popover
            placement="right"
            trigger="manual"
            v-model="roleVisible"
            width="400"
          >
            <div>
              <el-row :gutter="10">
                <el-col :span="6">
                  <img
                    alt
                    src="@/assets/images/menu/pop2.png"
                  />
                </el-col>
                <el-col :span="18">
                  <div class="popTit">【角色管理】板块</div>
                  <div class="popContent">
                    您可以创建角色名称，并给角色定义可以查看的菜单权限，
                    <span>如需配置用户管理权限必须先配置好【角色管理】对应的权限</span>
                  </div>
                  <div class="popButton">
                    <el-button
                      @click="cancels"
                      class="blackButton"
                      type="text"
                    >跳过</el-button>
                    <el-button
                      @click="nextTo(item)"
                      type="text"
                    >继续探索</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <span slot="reference"></span>
          </el-popover>
        </span>
        <span
          slot="title"
          v-else-if="isUserMenu(item)"
        >
          <span>{{ item.title }}</span>
          <span class="newIcon">新</span>
          <el-popover
            placement="right"
            trigger="manual"
            v-model="userVisible"
            width="400"
          >
            <div>
              <el-row :gutter="10">
                <el-col :span="6">
                  <img
                    alt
                    src="@/assets/images/menu/pop3.png"
                  />
                </el-col>
                <el-col :span="18">
                  <div class="popTit">【用户管理】板块</div>
                  <div class="popContent">
                    <span>创建好部门和角色权限后，</span> 你可以接下来添加用户并对用户进行部门和角色权限关联操作
                  </div>
                  <div class="popButton">
                    <el-button
                      @click="cancels"
                      class="blackButton"
                      type="text"
                    >跳过</el-button>
                    <el-button
                      @click="nextTo(item)"
                      type="text"
                    >我知道啦</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <span slot="reference"></span>
          </el-popover>
        </span>
        <span
          slot="title"
          v-else
        >{{ item.title }}</span>
      </el-menu-item>
    </template>

    <el-submenu
      :index="item.id"
      popper-class="layout-sub-menu"
      ref="subMenu"
      v-else
    >
      <template slot="title">
        <!-- <img :src="imageUrl(item)" v-if="isImage(item)" />
        <i v-else :class="item.icon"></i>-->
        <!-- <template v-if="item.icon"> -->
        <svg-icon :icon-class="item.icon || ''"></svg-icon>
        <!-- </template> -->
         <span slot="title" v-if="hasBage(item) && bage[item.name] && $store.state.log.sideStatus">
             <el-badge
              :value="bage[item.name]"
              class="item"
            >
            {{ item.title}}
            </el-badge>
          </span>
        <span
          slot="title"
          v-else-if="$store.state.log.sideStatus && isNewMenu(item)"
        >
          <span>{{ item.title}}</span>
          <span
            class="newIcon"
            v-if="nooType==1"
          >新</span>
          <el-popover
            placement="right"
            trigger="manual"
            v-model="visible"
            width="400"
          >
            <div>
              <el-row :gutter="10">
                <el-col :span="6">
                  <img
                    alt
                    src="@/assets/images/menu/pop.png"
                  />
                </el-col>
                <el-col :span="18">
                  <div class="popTit">新增【部门管理】等板块管理</div>
                  <div class="popContent">您可以对公司组织架构部门、角色权限以及子账号账户进行添加、删除等操作</div>
                  <div class="popButton">
                    <el-button
                      @click="cancels"
                      class="blackButton"
                      type="text"
                    >跳过</el-button>
                    <el-button
                      @click="nextTo(1)"
                      type="text"
                    >继续探索</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
            <span slot="reference"></span>
          </el-popover>
        </span>
        <span
          slot="title"
          v-else-if="$store.state.log.sideStatus && !isNewMenu(item)"
        >{{ item.title }}</span>
      </template>
      <sidebar-item
        :active-id="activeId"
        :item="child"
        :key="child.id"
        :bageObj="bageObj"
        v-for="child in item.children"
      />
    </el-submenu>
  </div>
</template>

<script>
	import roleApi from '@/services/info/role'
	export default {
		name: 'SidebarItem',
		props: {
			// route object
			item: {
				type: Object,
				required: true
			},
			basePath: {
				type: String,
				default: ''
			},
			activeId: String,
			bageObj: {
				type: Object,
				default: () => {
					return {}
				}
			},
      nooType: String,
		},
		inject: ['bubbleIdObj'],
		data() {
			return {
				isActive: false,
				bage: {},
				newMenus: ['个人中心', '部门管理', '角色管理', '用户管理'],
				visible: false,
				userVisible: false,
				roleVisible: false,
				depVisible: false,
				depMenus: ['部门管理'],
				roleMenus: ['角色管理'],
				userMenus: ['用户管理']
			}
		},
		created() {
			this.isActive = this.activeId === this.item.id
      this.setBageData();
		},
		watch: {
			activeId() {
				this.isActive = this.activeId === this.item.id
			},
			$route(to) {
				if (to.meta && to.meta.title && this.newMenus.includes(to.meta.title)) {
					if (!!this.bubbleIdObj.bubbleId && this.guide === 'tense') {
						if (to.meta.title === '部门管理') {
							this.depVisible = true
						} else if (to.meta.title === '角色管理') {
							this.roleVisible = true
						} else if (to.meta.title === '用户管理') {
							this.userVisible = true
						}
					}
				}
			},
			'bubbleIdObj.bubbleId'(nv, ov) {
				if (!!nv) {
					this.visible = true
				}
			},
      bageObj: {
				deep: true,
				handler(nv, ov) {
                    this.setBageData();
				}
			}
		},
		methods: {
      setBageData(){
        this.bage = {
          receiptConfirmationNew: '' ,
          breachManegement: this.bageObj.breachApply && this.bageObj.breachApply != 0 ? this.bageObj.breachApply + '' : '',
          ensureSendGoods:this.bageObj.deliverOrderNum && this.bageObj.deliverOrderNum != 0 ? this.bageObj.deliverOrderNum + '' : '',
          abnormalWayBill:this.bageObj.exceptionOrderCount && this.bageObj.exceptionOrderCount != 0 ? this.bageObj.exceptionOrderCount + '' : '',
          orderOverDue:this.bageObj.orderOverDueCount && this.bageObj.orderOverDueCount != 0 ? this.bageObj.orderOverDueCount + '' : '',
          settlementApplication: '',
          receiptSign: '',
            contractManagement: ''
        }

        if(this.bageObj.workOrder && this.bageObj.workOrder.totalSize && this.bageObj.workOrder.totalSize != 0){
          this.bage.receiptConfirmationNew = this.bageObj.workOrder.totalSize + ''
        }
        if(this.bageObj.applySettleCount && this.bageObj.applySettleCount.model && this.bageObj.applySettleCount.model != 0){
          this.bage.settlementApplication = this.bageObj.applySettleCount.model + ''
        }else {
          if(this.bageObj.applySettleCount && this.bageObj.applySettleCount != 0){
            this.bage.settlementApplication = this.bageObj.applySettleCount + ''
          }
        }
        if(this.bageObj.expressCount && this.bageObj.expressCount.consignorCount && this.bageObj.expressCount.consignorCount != 0){
          this.bage.receiptSign = this.bageObj.expressCount.consignorCount + ''
        }
        if(this.bageObj.signatureCount && this.bageObj.signatureCount > 0){
            this.bage.contractManagement = '签'
        }
      },
			cancels() {
				this.visible = false
				this.userVisible = false
				this.roleVisible = false
				this.depVisible = false
			},
			isNewMenu(menu) {
				if (this.newMenus.includes(menu.title)) {
					return true
				} else {
					return false
				}
			},

			isDepMenu(menu) {
				if (this.depMenus.includes(menu.title)) {
					return true
				} else {
					return false
				}
			},
			isRoleMenu(menu) {
				if (this.roleMenus.includes(menu.title)) {
					return true
				} else {
					return false
				}
			},
			isUserMenu(menu) {
				if (this.userMenus.includes(menu.title)) {
					return true
				} else {
					return false
				}
			},
			nextTo(val) {
				// 第一步
				if (!(val instanceof Object) && val == 1) {
					this.visible = false
					this.$router.push({
						name: 'department',
						params: {
							guide: 'tense'
						}
					})
				} else {
					if (val.name == 'department') {
						this.depVisible = false
						this.$router.push({
							name: 'roleManager',
							params: {
								guide: 'tense'
							}
						})
					} else if (val.name == 'roleManager') {
						this.roleVisible = false
						this.$router.push({
							name: 'accountManage',
							params: {
								guide: 'tense'
							}
						})
					} else {
						this.userVisible = false
						roleApi.bubbleGuideAdd({ bubbleId: this.bubbleIdObj.bubbleId })
					}
				}
			},
			hasBage(menu) {
				let menus = [
					'receiptConfirmationNew',
					'breachManegement',
					'ensureSendGoods',
					'abnormalWayBill',
					'orderOverDue',
					'settlementApplication',
                    'receiptSign',
                    'contractManagement'
				]
				if (menus.includes(menu.name)) {
					return true
				} else {
					return false
				}
			},
			isImage(item) {
				let isImage = false
				if (item.icon) {
					let arr = item.icon.split('.')
					let imageType = ['png', 'jpeg', 'jpg']
					isImage = arr.length > 1 && imageType.includes(arr[1])
				}
				return isImage
			},
			imageUrl(item) {
				if (this.isActive) {
					let icon = item.icon.split('.')[0]
					return require('@/assets/images/menu/' + icon + '_active.png')
				} else {
					return require('@/assets/images/menu/' + item.icon)
				}
			}
		},
		computed: {
			guide() {
				return this.$route.params.guide
			},
      /**
       * zczy-11266_【客服进线】发票管理报表权限颗粒度细分
       */
      InvoiceStatements(){
        return ['发票管理报表','对账收件信息管理']
      }
		}
	}
</script>

<style lang="scss">
.layout-sub-menu {
	.el-menu-item,
	.el-submenu {
		img {
			width: 18px;
			height: 18px;
			margin-right: 8px;
		}
	}
  
}
.side-bar-item {
  .el-badge__content.is-fixed {
    top: 9px;
    right: 0px;
  }
}
.newIcon {
	margin-left: 10px;
	color: #fff;
	background: #ff602e;
	font-size: 12px;
	text-align: center;
	padding: 0 8px;
	border-radius: 8px 8px 8px 0;
}
.popTit {
	font-size: 16px;
	font-weight: 600;
	color: #333333;
	line-height: 30px;
}
.popContent {
	font-size: 12px;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
	span {
		color: #ff602e;
	}
}
.popButton {
	text-align: right;
	.blackButton {
		color: #333333;
	}
}
// .el-submenu {
// 	.el-popover {
// 		position: fixed;
// 		padding: 24px;
// 	}
// }
</style>
