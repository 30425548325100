export default [
    {
        path: '/publicPage/fenceManagement',
        name: 'fenceManagement',
        component: () => import(/* webpackChunkName: "common" */ './fenceManagement.vue'),
        meta: {
            authPass: true,
            title: '围栏管理',
        }
    },
    {
        path: '/publicPage/earlyWarningData',
        name: 'earlyWarningData',
        component: () => import(/* webpackChunkName: "common" */ './earlyWarningData.vue'),
        meta: {
            authPass: true,
            title: '预警数据',
        }
    },
    {
        path: '/publicPage/earlyWarningDataDetail',
        name: 'earlyWarningDataDetail',
        component: () => import(/* webpackChunkName: "common" */ './earlyWarningDataDetail.vue'),
        meta: {
            authPass: true,
            title: '预警详情',
        }
    }
];
//
