export default [
    {
        path: '/publicPage/ruleSet',
        name: 'ruleSet',
        component: () => import(/* webpackChunkName: "common" */ './ruleSet.vue'),
        meta: {
            authPass: true,
            title: '发单规则配置'
        }
    }
];
