export default{
    addVipKefuData(){
        let a = document.getElementsByClassName('zczy-online-service')[0].getElementsByTagName('a');
        let classNames = a[0].className;
        a[0].className = classNames + ' is-user-vip';
        zc('config',{
            channelid: 3,
            custom: true,
            refresh: true,
            is_vip: 1,
            "manual": false, //设置成手动初始化
            uname: 'VIP:' + localStorage.getItem('memberName'), //客户名称
            // realname:"丁丁猫", //客户真实姓名
        })
    },
    removeVipKefuData(){
        let zhichiServer = document.getElementById('zhichiScript');
        let url = zhichiServer.getAttribute('src');
        let a = document.getElementsByClassName('zczy-online-service')[0].getElementsByTagName('a');
        let classNames = a[0].className
        a[0].className = classNames.replace(/(is-user-vip)/gi,"");
        zc('config',{
            channelid: 3,
            custom: true,
            refresh: true,
            is_vip: 0,
            "manual": false, //设置成手动初始化
            // realname:"丁丁猫", //客户真实姓名
        })
    }
}