export default [
  {
    path: "/publicPage/invoiceApply",
    name: "invoiceApply",
    component: () => import(/* webpackChunkName: "common" */ "./invoiceApply"),
    meta: {
      authPass: true,
      title: "发票申请",
    },
  },
  {
    path: "/publicPage/invoiceCancel",
    name: "invoiceCancel",
    component: () => import(/* webpackChunkName: "common" */ "./invoiceCancel"),
    meta: {
      authPass: true,
      title: "发票作废",
    }, // 发票作废
  },
  {
    path: "/publicPage/invoiceMail",
    name: "invoiceMail",
    component: () => import(/* webpackChunkName: "common" */ "./invoiceMail"),
    meta: {
      authPass: true,
      title: "发票邮寄",
    }, // 发票邮寄
  },
  {
    path: "/publicPage/invoiceInfo",
    name: "invoiceInfo",
    component: () => import(/* webpackChunkName: "common" */ "./invoiceInfo"),
    meta: {
      authPass: true,
      title: "开票信息",
    }, // 开票信息
  },
  {
    path: "/publicPage/accountManager",
    name: "accountManager",
    component: () =>
      import(/* webpackChunkName: "common" */ "./accountManager"),
    meta: {
      authPass: true,
      title: "对账收件信息管理",
    },
  },
  {
    path: "/publicPage/invoiceManagerList",
    name: "invoiceManagerList",
    component: () =>
      import(/* webpackChunkName: "common" */ "./invoiceManagerList"),
    meta: {
      authPass: true,
      title: "发票管理列表",
    }, // 发票管理列表
  },
  {
    path: "/publicPage/invoiceDetail",
    name: "invoiceDetail",
    component: () => import(/* webpackChunkName: "common" */ "./invoiceDetail"),
    meta: {
      authPass: true,
      title: "发票申请详情",
    }, // 发票详情
  },
  {
    path: "/publicPage/packDetail",
    name: "packDetail",
    component: () => import(/* webpackChunkName: "common" */ "./packDetail"),
    meta: {
      authPass: true,
      title: "发票详情",
    }, // 发票作废详情
  },
  {
    path: "/publicPage/expressageDetail",
    name: "expressageDetail",
    component: () =>
      import(/* webpackChunkName: "common" */ "./expressageDetail"),
    meta: {
      authPass: true,
      title: "快递详情",
    }, // 发票作废详情
  },
  {
    path: "/publicPage/applyContent",
    name: "applyContent",
    component: () =>
      import(/* webpackChunkName: "common" */ "./components/applyContent"),
    meta: {
      authPass: true,
      title: "发票申请",
    }, // 发票申请步骤
  },
  {
    path: "/publicPage/cancellationRecord",
    name: "cancellationRecord",
    component: () =>
      import(/* webpackChunkName: "common" */ "./cancellationRecord"),
    meta: {
      authPass: true,
      title: "发票作废记录",
    }, // 发票管理列表
  },
];
