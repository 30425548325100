export default [
	{
		path: '/publicPage/operationData',
		name: 'operationData',
		component: () => import(/* webpackChunkName: "common" */ './operationData'),
		meta: {
			authPass: true,
			title: '运营数据'
		}
	},
	{
		path: '/publicPage/operationDetail',
		name: 'operationDetail',
		component: () => import(/* webpackChunkName: "common" */ './operationDetail'),
		meta: {
			authPass: true,
			title: '运营明细'
		}
	}
]
