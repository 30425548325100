// import logo from '@/assets/images/login/national.png';

const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
let hostname = location.hostname;
let domain;

if (hostname === 'localhost' || reg.test(hostname)) {
    domain = hostname;
} else {
    hostname = hostname.split('.');
    let len = hostname.length;

    domain = `.${hostname[len - 2]}.${hostname[len - 1]}`;
}

window.domain = domain;

const config = {
    projectName: '户主前台',
    logo: '',
    loginBg: '',
    pageHeader: {
        screenful: true,
        theme: true,
        lang: true,
        message: true,
        height: 56
    },
    historyMode: 'history',
    axiosResponseType: 'json',
    customBg: [],
    customColor: [],
    home: '/publicPage/myFortune',
    domain
};

export default {
    development: Object.assign(
        {
            axiosBaseUrl: '/'
        },
        config
    ),
    production: Object.assign(
        {
            axiosBaseUrl: ''
        },
        config
    )
};
