export default [
	{
		path: '/publicPage/wareHouseManage',
		name: 'wareHouseManage',
		component: () => import(/* webpackChunkName: "common" */ './index.vue'),
		meta: {
			authPass: true,
			title: '仓库名称管理'
		}
	},
		
]
