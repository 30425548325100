export default [
    {
        path: '/publicPage/setFranchiseCapacity',
        name: 'setFranchiseCapacity',
        component: () => import(/* webpackChunkName: "common" */ './setFranchiseCapacity.vue'),
        meta: {
            authPass: true,
            title: '加盟运力组管理'
        }
    }
];
