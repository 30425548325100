import log from './log'
import user from './user'
import permission from './permission'
import waybillPublish from './waybillPublish'
import invoice from './invoice'

export default {
	log,
	user,
	permission,
	waybillPublish,
	invoice
}
