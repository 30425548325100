export default [
    {
        path: '/publicPage/recipientsManagePage',
        name: 'recipientsManagePage',
        component: () => import(/* webpackChunkName: "common" */ './recipientsManagePage.vue'),
        meta: {
            authPass: true,
            title: '收件人管理'
        }
    }
];
