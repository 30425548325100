export default [
    {
        path: '/publicPage/goodsName',
        name: 'goodsName',
        component: () => import(/* webpackChunkName: "common" */ './goodsName.vue'),
        meta: {
            authPass: true,
            title: '货物名称管理'
        }
    }
];
