/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
	if (time === 1) {
		return time + label
	}
	return time + label + 's'
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
	const between = Date.now() / 1000 - Number(time)
	if (between < 3600) {
		return pluralize(~~(between / 60), ' minute')
	} else if (between < 86400) {
		return pluralize(~~(between / 3600), ' hour')
	} else {
		return pluralize(~~(between / 86400), ' day')
	}
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
	const si = [
		{ value: 1e18, symbol: 'E' },
		{ value: 1e15, symbol: 'P' },
		{ value: 1e12, symbol: 'T' },
		{ value: 1e9, symbol: 'G' },
		{ value: 1e6, symbol: 'M' },
		{ value: 1e3, symbol: 'k' }
	]
	for (let i = 0; i < si.length; i++) {
		if (num >= si[i].value) {
			return (
				(num / si[i].value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
			)
		}
	}
	return num.toString()
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(money) {
	const sysmbol = ''
	const places = 2
	const zero = `${sysmbol}0.00`
	if (isNaN(money) || money === '') {
		return zero
	}

	if (money && money != null) {
		money = `${money}`
		const left = money.split('.')[0] // 小数点左边部分
		let right = money.split('.')[1] // 小数点右边
		// 保留places位小数点，当长度没有到places时，用0补足。
		right = right
			? right.length >= places
				? '.' + right.substr(0, places)
				: '.' + right + '0'.repeat(places - right.length)
			: '.' + '0'.repeat(places)
		var temp = left
			.split('')
			.reverse()
			.join('')
			.match(/(\d{1,3})/g) // 分割反向转为字符串然后最多3个，最少1个，将匹配的值放进数组返回
		return (
			(Number(money) < 0 ? '-' : '') +
			sysmbol +
			temp
				.join(',')
				.split('')
				.reverse()
				.join('') +
			right
		) // 补齐正负号和货币符号，数组转为字符串，通过逗号分隔，再分割（包含逗号也分割）反向转为字符串变回原来的顺序
	} else if (money === 0) {
		return zero
	} else {
		return zero
	}
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}
/**
 * mobile ****
 */
export function mobileStar(string) {
	if (!string) {
		return
	}
	var reg = /^(\d{3})\d{4}(\d{4})$/
	return string.replace(reg, '$1****$2')
}
/**
 *保留两位小数
 */
export function twoPonit(string) {
	return string.toFixed(2)
}
/**
 *倒计时
 */
export function timeCountDown(time) {
	let leftSecond = 0
	// 计算一天的秒数
	let daySecond = 24 * 60 * 60
	// 得到剩余天数
	let days = time / daySecond
	// 得到剩余天数(整数)
	let daysFloor = Math.floor(days)
	leftSecond = time - daysFloor * daySecond
	// 得到剩余天数以外的小时数
	let hourSecond = 60 * 60
	let hours = leftSecond / hourSecond
	// 得到剩余天数以外的小时数(整数)
	let hoursFloor = Math.floor(hours)
	leftSecond = leftSecond - hoursFloor * hourSecond
	// 得到尾剩余分数
	let minutes = leftSecond / 60
	let minutesFloor = Math.floor(minutes)
	// 得到尾剩余分数(整数)
	leftSecond = leftSecond - minutesFloor * 60
	// 得到尾剩余秒数(整数)
	let seconds = leftSecond
	// 天数取三位,不足时前边补0
	// if (daysold<10) { daysold="0"+daysold }
	// 天数取三位,不足时前边补0
	// if (daysold<100) { daysold="0"+daysold }
	// 小时取两位,不足补0
	if (hoursFloor < 10) {
		hoursFloor = '0' + hoursFloor
	}
	// 分数取两位,不足补0
	if (minutesFloor < 10) {
		minutesFloor = '0' + minutesFloor
	}
	// 秒数取两位,不足补0
	if (seconds < 10) {
		seconds = '0' + seconds
	}

	return `<b>${daysFloor}</b>天<b>${hoursFloor}</b>小时<b>${minutesFloor}</b>分<b>${seconds}秒</b>`
}
