export default [
    {
        path: '/publicPage/jmMemberSet',
        name: 'jmMemberSet',
        component: () => import(/* webpackChunkName: "common" */ './index.vue'),
        meta: {
            authPass: true,
            title: '加盟运力会员状态配置'
        }
    }
];
