export default [
    {
        path: '/publicPage/inquiryPage',
        name: 'inquiryPage',
        component: () => import(/* webpackChunkName: "common" */ './inquiryPage.vue'),
        meta: {
            authPass: true,
            title: '询价专区',
        }
    },
    {
        path: '/publicPage/addInquiryPage',
        name: 'addInquiryPage',
        component: () => import(/* webpackChunkName: "common" */ './addInquiryPage.vue'),
        meta: {
            authPass: true,
            noTitle: true
        },
    },
    {
        path: '/publicPage/inquiryDetail',
        name: 'inquiryDetail',
        component: () => import(/* webpackChunkName: "common" */ './inquiryDetail.vue'),
        meta: {
            authPass: true,
            title: '询价详情',
        }
    }
];
