export default [{
    path: '/publicPage/accountManage',
    name: 'accountManage',
    component: () => import( /* webpackChunkName: "common" */ './accountManage/index.vue'),
    meta: {
      authPass: true,
      title: '用户管理'
    }
  },
  {
    path: '/publicPage/roleManager',
    name: 'roleManager',
    component: () => import( /* webpackChunkName: "common" */ './role/index.vue'),
    meta: {
      authPass: true,
      title: '角色管理'
    }
  },
  {
    path: '/publicPage/trainMaterial',
    name: 'trainMaterial',
    component: () => import( /* webpackChunkName: "common" */ './trainMaterial/index.vue'),
    meta: {
      authPass: true,
      title: '我的培训'
    }
  },
  {
    path: '/publicPage/securityService',
    name: 'securityService',
    component: () => import( /* webpackChunkName: "common" */ './securityService/index.vue'),
    meta: {
      authPass: true,
      title: '保障服务'
    }
  },
  {
    path: '/publicPage/department',
    name: 'department',
    component: () => import( /* webpackChunkName: "common" */ './department/index.vue'),
    meta: {
      authPass: true,
      title: '部门管理'
    }
  },
  {
    path: '/publicPage/limit',
    name: 'limit',
    component: () => import( /* webpackChunkName: "common" */ './limit/index.vue'),
    meta: {
      authPass: true,
      title: '会员类型额度'
    }
  },
  {
    path: '/publicPage/limitRecords',
    name: 'limitRecords',
    component: () => import( /* webpackChunkName: "common" */ './limit/components/records'),
    meta: {
      authPass: true,
      title: '操作记录'
    }
  },
  {
    path: '/publicPage/regulatoryPlatform',
    name: 'regulatoryPlatform',
    component: () => import( /* webpackChunkName: "common" */ './role/regulatoryPlatform/regulatoryPlatform'),
    meta: {
      authPass: true,
      title: '监管平台角色管理'
    }
  }
]