export default [
    {
        path: '/publicPage/vehicleNotCarried',
        name: 'vehicleNotCarried',
        component: () => import(/* webpackChunkName: "common" */ './vehicleNotCarried.vue'),
        meta: {
            authPass: true,
            title: '车辆未承运时长报表'
        }
    }
];
