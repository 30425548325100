/**
 * 插件
 */
 import Router from 'vue-router'
 import Promise from 'promise-polyfill'
 import elementUi, { Message, Loading, MessageBox } from 'element-ui'
 import axios from 'axios'
 import config from '@/assets/utils/config'
 import store from '@/store'
 import components from '@/components';
 import {Decrypt,Encrypt} from "./cryptoJS";
 import passAuth from './passAuth';
 import noEncty from './noEncry';
 const isProduction = ['production', 'prod'].includes(process.env.NODE_ENV)
 const errObj = {
	 response: {
		 status: 401,
		 data: {
			 code: 401,
			 message: 'token失效，请重新登陆'
		 }
	 }
 }
 let errTotal = 0 // 抛出异常次数
 let loading
 let requests = []
 let isRefreshing = false
 let loadingNum = 0 // 内存中正在请求的数量
 let domain = { domain: window.domain }
 export default {
	 startLoading() {
		 if (loadingNum === 0) {
			 loading = Loading.service({
				 lock: true,
				 text: 'Loading',
				 spinner: 'el-icon-loading',
				 background: 'rgba(0, 0, 0, 0.7)'
			 })
		 }
		 loadingNum++
	 },
	 endLoading() {
		 loadingNum--
		 if (loadingNum <= 0 && loading) {
			 loading.close()
		 }
	 },
	 loadGlobalComponents(Vue) {
		 for (let k in components) {
			 let componentModules = components[k]
			 for (let k1 in componentModules) {
				 let component = componentModules[k1]
				 if (component.length) {
					 Vue.component(component[0], component[1])
				 } else {
					 Vue.component(component.name, component)
				 }
			 }
		 }
	 },
	 defineVueProp(Vue) {
		 Vue.prop = (key, value) => {
			 Vue[`$${key}`] = Vue.prototype[`$${key}`] = value
		 }
	 },
 
	 errHandle(Vue) {
		 // MessageBox.confirm('用户未登录或会话已失效', '提示', {
		 // 	confirmButtonText: '确定',
		 // 	type: 'warning'
		 // }).then(() => {
			// 银轮定制
			// let channel = localStorage.getItem('channel') 
			Message.warning('用户未登录或会话已失效,请重新登录')
		 	store.dispatch('resetState')
		 	// localStorage.setItem('channel', channel)
			let obj = { name: 'Login' }

			// if(channel) {
			// 	obj.query = {
			// 		channel: channel
			// 	}
			// }
		 	Vue.$router.push(obj)
		 // })
	 },
	 errNoAccess(Vue, data) {
		 if(data.data.msg){
			 Message.warning(data.data.msg)
		 }else {
			 Message.warning('抱歉，您没有权限！')
		 }
		 // Vue.$router.push({ path: '/' })
	 },
	 getPublicQueryData(number) {
		 var arr = new Array();
		 var arr1 = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9');
		 var nonceStr = '';
		 for (var i = 0; i < number; i++) {
			 var n = Math.floor(Math.random() * 10);
			 arr[i] = arr1[n];
			 nonceStr += arr1[n];
		 }
		 return {
			 uuid: nonceStr,
			 timestamp: new Date().getTime() + '',
			 uuUserId: localStorage.getItem('uuUserId')
		 };
	 },
	 install(Vue) {
		 // 定义Vue全局属性
		 this.defineVueProp(Vue)
		 // 加载核心组件
		 // this.loadGlobalComponents(Vue);
		 elementUi.Dialog.props.closeOnClickModal.default = false
		 Vue.use(elementUi, { size: 'small' })
		 // 加载核心插件
		 Vue.use(Router)
		 // if (isProduction) {
		 //     Vue.prototype.$ELEMENT = { size: 'small' };
		 // } else {
		 //     Vue.use(elementUi, { size: 'small' });
		 //     // 加载核心插件
		 //     Vue.use(Router);
		 // }
 
		 // 附加Vue原型属性
		 Vue.prop('config', config[process.env.NODE_ENV])
 
		 // 设置axios默认参数
		 axios.defaults.baseURL = Vue.$config.axiosBaseUrl
		 axios.defaults.responseType = Vue.$config.axiosResponseType
		 axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
		 // axios.defaults.headers.common['Authorization'] = ;
		 axios.defaults.timeout = 3 * 60 * 1000
		 axios.defaults.withCredentials = true
 
		 axios.interceptors.request.use(
			 config => {
				 // let refreshToken = Vue.$cookie.get('refreshToken', domain); // 从cookie中取出token过期时间
				 let rAuth = localStorage.getItem('tokenId')
				//  if (config.data) {
				// 	 if (config.data.load !== false) {
				// 		 this.startLoading()
				// 	 }
				// 	 delete config.data.load
				//  } else if (config.load !== false) {
				// 	 this.startLoading()
				//  }
				 delete config.load
				 let isPassAuth = passAuth.find(it => {
					 return it === config.url
				 });
				 if(!isPassAuth){
					 // 添加请求头
					if(rAuth){
						config.headers.common['aioSsoTokenId'] = rAuth
						if(config.url.indexOf('/resourceReserveInfo') !== -1){
							config.headers.common['ucSsoTokenId'] = rAuth
						}
						config.headers.common['plateFormFlag'] = 20;
					}
				 }
				 // 对请求参数加密, 登录接口和刷新token接口绕过
				 // if(config.url !== '/oms-consignor-pc/order/evaluation/toEvaluationList'){
				 // 	let queryData = {
				 // 		...(config.data || {}),
				 // 		...this.getPublicQueryData(10)
				 // 	}
				 // 	config.data = {
				 // 		encryParam: Encrypt(JSON.stringify(queryData))
				 // 	}
				 // }
				 const isProduction = ['production', 'prod'].includes(process.env.NODE_ENV);
				 if(isProduction && noEncty.indexOf(config.url) == -1 && (config.url.indexOf('/bigdata/business/dataCollect') === -1)){
					 let queryData = {
						 ...(config.data || {}),
						 ...this.getPublicQueryData(10)
					 }
					 config.data = {
						 encryParam: Encrypt(JSON.stringify(queryData))
					 }
				 }
				 // if (config.url.indexOf('/whale-pc') !== -1) {
				 // 	let queryData1 = {
				 // 		...(config.data || {}),
				 // 		...this.getPublicQueryData(10)
				 // 	}
				 // 	config.data = {
				 // 		encryParam: Encrypt(JSON.stringify(queryData1))
				 // 	}
				 // }
 
				 return config
			 },
			 error => {
				 return Promise.reject(error)
			 }
		 )
 
		 axios.interceptors.response.use(
			 response => {
				 if(loadingNum > 0){
					 this.endLoading()
				 }
				 // if (response.data && response.data.resultCode === '401') {
				 //     let sessionExistence = localStorage.getItem('tokenEffective');
				 //     if (sessionExistence && errTotal === 0) {
				 //         Message({
				 //             type: 'warning',
				 //             message: '页面失效，请重新登录'
				 //         });
				 //         errTotal++;
				 //     }
				 //     store.dispatch('resetState');
				 //     Vue.$router.push({ path: '/login' });
				 //     return;
				 // }
				 // // 
				 if (axios.isCancel(response)) {
					 console.log('Request canceled', response.message)
				 }
				 // if (response.config.url === '/whale-merge-pc/home/selfCenter') {
				 // 	response.data = JSON.parse(Base64.decode(response.data))
				 // }
				 if(response.data.resultCode && response.data.resultCode !== '0000' && response.data.resultCode !== '200'){
					return Promise.reject({
						code: '500',
						data: response.data
					})
				 }
 
				 if (response.data && response.data.data && response.data.data.resultCode && response.data.data.resultCode !== '0000' && Number(response.data.data.resultCode) !== 200) {
					 // 发布运单resultCode为2222的时候不弹提示消息
					 let noErrorTipCode = ['1002', '2222', '1001', '3027', '3028', '3029', '0001'];
					 if(noErrorTipCode.indexOf(response.data.data.resultCode) !== -1){
						 return Promise.reject({
							 ...response.data,
							 noErrorTip: true
						 })
					 }
                     if(response.data.data.resultMsg.indexOf('没有查到运价') !== -1){
                        return Promise.reject({
                            ...response.data,
                            noErrorTip: true
                        })
                     }
					 return Promise.reject(response.data)
					 
				 }
				 if (response.data && response.data.code && Number(response.data.code) !== 200 && response.data.code !== '0000') {
                    if (response.data && response.data.code == '999') {
                        return Promise.reject({
                            ...response.data,
                            noErrorTip: true
                        })
                    }
					 return Promise.reject(response.data)
				 }
				 if (response.data && response.data.code == '200') {
					 errTotal = 0;
					 return response
				 }
				 if (response.data && (response.data.resultCode == '0000' || response.data.resultCode == '200')) {
					errTotal = 0;
					return response
				}
				 if (response.status >= 200 && response.status < 300) {
					 errTotal = 0;
					 localStorage.setItem('tokenEffective', 'effective')
					 return response
				 }
 
				 return Promise.reject(response.data)
			 },
			 error => {
				 this.endLoading()
				 console.log(error.response)
				 if (error.response) {
					 if (error.response.status === 403) {
						 this.errNoAccess(Vue, error.response)
						 return
					 }
					 if (error.response.status === 401 && window.location.pathname !== '/login' && errTotal === 0) {
						 errTotal++
						 this.errHandle(Vue)
						 return
					 }
					 // response 返回 status 为401 并且 当切页面不是登录页面 并且 抛出错误统计 为0 时，提示token 异常，重新登录
					 if (error.response.data.code === 'AIO-401' && window.location.pathname !== '/login' && errTotal === 0) {
						 errTotal++
						 this.errHandle(Vue)
						 return
					 }
				 }
				 let errorData = {}
				 if (error.response.data) {
					 errorData = error.response.data
				 }
				 return Promise.reject(errorData)
			 }
		 )
	 }
 }
 
