export function filterAsyncRoutes(menus) {
    console.log('---in filterAsyncRoutes----');
    console.log(menus);
    return [];
}

const state = {
    menus: '',
    routes: []
};
const mutations = {
    SET_MENUS: (state, menus) => {
        state.menus = menus;
    },
    SET_ROUTES: (state, routes) => {
        state.routes = routes;
    },
    // 用于退出登录重置数据
    resetState(state) {
        state = {
            menus: '',
            routes: []
        }
    }
};

const actions = {
    generateRoutes({ commit }, menus) {
        return new Promise(resolve => {
            commit('SET_MENUS', menus);
            const accessedRoutes = filterAsyncRoutes(menus);
            commit('SET_ROUTES', accessedRoutes);
            resolve(accessedRoutes);
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
