export default [
    {
        path: '/publicPage/waybillTatistics',
        name: 'waybillTatistics',
        component: () => import(/* webpackChunkName: "common" */ './waybillTatisticsPage.vue'),
        meta: {
            authPass: true,
            title: '统计报表',
            noTitle: true
        }
    }
];
