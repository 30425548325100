export default [
    {
        path: '/publicPage/settlementApplication',
        name: 'settlementApplication',
        component: () => import(/* webpackChunkName: "common" */ './settlementApplication.vue'),
        meta: {
            authPass: true,
            title: '',
            noTitle: true
        }
    }
];
