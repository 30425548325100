export default [
    {
        path: '/publicPage/routeFenceRelationship',
        name: 'routeFenceRelationship',
        component: () => import(/* webpackChunkName: "common" */ './routeFenceRelationship.vue'),
        meta: {
            authPass: true,
            title: '路线围栏关联关系'
        }
    }
];