import goodsName from './goodsName';
import goodsSpecificat from './goodsSpecificat';
import waybillPublish from './waybillPublish';
import myWaybill from './myWaybill';
import consignor from './consignor';
import consignorNew from './consignorNew';
import contract from './contract';
import settlementApplication from './settlementApplication';
import evaluation from './evaluation';
import settlement from './settlement';
import settlementImport from './settlementImport';
import abnormalWayBill from './abnormalWayBill';
import receiptSignIn from './receiptSignIn';
import breach from './breach';
import changeManegement from './changeManegement';
import orderMonitored from './orderMonitored';
import ensureSendGoods from './ensureSendGoods';
import problemFeedback from './problemFeedback';
import orderOverDue from './orderOverDue';
import onRoadMonitored from './onRoadMonitored';
import receiptSign from './receiptSign';
import offlineZone from './offlineZone';
import inquiryPage from './inquiryPage';
import transportPlan from './transportPlan';
import JidongShipment from './JidongShipment';
import settlementApplicationExamine from './settlementApplicationExamine';
import weightListDateManagement from './weightListDateManagement'

export default [
  ...goodsName,
  ...goodsSpecificat,
  ...waybillPublish,
  ...myWaybill,
  ...consignor,
  ...consignorNew,
  ...contract,
  ...settlementApplication,
  ...settlementImport,
  ...evaluation,
  ...settlement,
  ...abnormalWayBill,
  ...receiptSignIn,
  ...breach,
  ...changeManegement,
  ...orderMonitored,
  ...ensureSendGoods,
  ...problemFeedback,
  ...orderOverDue,
  ...onRoadMonitored,
  ...receiptSign,
  ...offlineZone,
  ...inquiryPage,
  ...transportPlan,
  ...JidongShipment,
  ...settlementApplicationExamine,
  ...weightListDateManagement
];

// export default [...goodsName, ...goodsSpecificat, ...waybillPublish, ...myWaybill, ...consignor, ...orderOverDue, ...contract, ...settlementApplication, ...evaluation, ...settlement, ...abnormalWayBill, ...receiptSignIn, ...breach, ...changeManegement,...problemFeedback];
