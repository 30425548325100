export default [
    {
        path: '/publicPage/transportGroup',
        name: 'transportGroup',
        component: () => import(/* webpackChunkName: "common" */ './transportGroup.vue'),
        meta: {
            authPass: true,
            title: '运力组管理'
        }
    }
];
