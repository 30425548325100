export default [
    {
        path: '/publicPage/settlementImport',
        name: 'settlementImport',
        component: () => import(/* webpackChunkName: "common" */ './settlementImport.vue'),
        meta: {
            authPass: true,
            title: '结算导入'
        }
    }
];
